import rootReducer from './reducers/RootReducer'
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'

// export default function configureStore() {
//     const store = createStore(rootReducer, applyMiddleware(thunk))
//     return store
// }

const configureStore = () => {
    const store = createStore(rootReducer, applyMiddleware(thunk))
    return store
};
  
const store = configureStore();
  
export default store;
  