import WalletConnectProvider from "@walletconnect/web3-provider"
import Web3Modal from 'web3modal'
import Fortmatic from "fortmatic"
import Portis from "@portis/web3";

const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: "fe144c9b7ccd44fc9f4ef53807df0bc5" // required
      }
    },
    fortmatic: {
      package: Fortmatic, // required
      options: {
        key: "pk_live_633916DC39808625" // required
      }
    },
    portis: {
        package: Portis, // required
        options: {
          id: "03f7a85e-c77c-42d0-b307-ff74a0ef6ae6" // required
        }
      }
};

const WalletConnector = new Web3Modal({
    network: "rinkeby", // optional
    cacheProvider: true, // optional
    theme: "dark",
    providerOptions // required
});

const initialState = {
  network: undefined,
  walletConnector: WalletConnector,
  loading: false,
  account: null,
  smartContract: null,
  web3: null,
  errorMsg: "",
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NETWORK":
      return {
        ...state,
        network: action.network
      }
    case "SET_WEB3":
      return {
        ...state,
        web3: action.payload
      }
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        smartContract: action.payload.smartContract,
        web3: action.payload.web3,
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
