const initState = {
    user: null,
    currentUser: null,
    loadingCurrentUser: false,
    firebaseUser: null,
    nfts: [],
    nftCount: 0
  }
  
const UserReducer = (state=initState,action) => {
  switch (action.type){
    
    case "UPDATE_USER":
      return {
        ...state,
        user: action.user
      }
    case "UPDATE_CURRENT_USER":
      return {
        ...state,
        currentUser: action.currentUser,
        loadingCurrentUser: false
      }
    case "SET_LOADING_CURRENT_USER":
      return {
        ...state,
        loadingCurrentUser: action.loadingCurrentUser
      }
    case "UPDATE_CURRENT_USER_NFTS":
      return {
        ...state,
        nfts: action.nfts
      }
    case "UPDATE_CURRENT_USER_TOTAL_NFT_COUNT":
      return {
        ...state,
        nftCount: action.nftCount
      }
    case "UPDATE_FIREBASE_USER":
      return {
          ...state,
          firebaseUser: action.firebaseUser
      }
          
    case "CLEAR_USER":
      return {
          ...state,
          user: null,
          firebaseUser: null
      }
    default: 
      return state
  }
}
  
export default UserReducer