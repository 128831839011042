import axios from 'axios';
import { auth } from '../../firebase';

export const getUserById = (userId, firebaseId) => {
    return(dispatch) => {
        axios({
            url: "https://genfinity.herokuapp.com/session/user/" + userId,
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + firebaseId,
            },
            withCredentials: true,
        })
        .then((res) => {
            dispatch(updateUser(res.data))
        }).catch(err => {
            console.log(err)
            dispatch(updateUser(null))
        });
    };
}

export const getCurrentUser = (userId) => {
    return(dispatch) => {
        if (!!userId) {
        const authUser = auth.currentUser;

        if (!!authUser) {
            authUser.getIdToken().then((idToken) => {
                dispatch(setLoadingCurrentUser(true))
                axios({
                    url: "https://genfinity.herokuapp.com/session/user/" + userId,
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + idToken,
                    },
                    withCredentials: true,
                })
                .then((res) => {
                    dispatch(updateCurrentUser(res.data))
                }).catch(err => {
                    dispatch(updateCurrentUser(null))
                    console.log(err);
                });
            });
            }
        }
    }
}

export const createUser = (firebaseId) => {
    return(dispatch) => {
        axios({
            url: "https://genfinity.herokuapp.com/session/login",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + firebaseId,
            },
            data: {provider: 'Google'},
            withCredentials: true,
          })
            .then((res) => {
                dispatch(updateUser(res.data))
            //   return Promise.resolve({ success: true, user });
            })
            .catch((error) => {
                console.log(error)
                dispatch(updateUser(null))
            //   return Promise.reject(error);
            });
    };
}

export function updateCurrentUser(currentUser) {
    return {
        type: 'UPDATE_CURRENT_USER',
        currentUser
    }
}

export function setLoadingCurrentUser(loadingCurrentUser) {
    return {
        type: 'SET_LOADING_CURRENT_USER',
        loadingCurrentUser
    }
}

export function updateUser(user) {
    return {
        type: 'UPDATE_USER',
        user
    }
}

export function updateFirebaseUser(firebaseUser) {
    return {
        type: 'UPDATE_FIREBASE_USER',
        firebaseUser
    }
}


export function updateCurrentUserNfts(nfts) {
    return {
        type: 'UPDATE_CURRENT_USER_NFTS',
        nfts
    }
}

export function updateCurrentUserTotalNftCount(nftCount) {
    return {
        type: 'UPDATE_CURRENT_USER_TOTAL_NFT_COUNT',
        nftCount
    }
}
