import React, { useState, useEffect } from "react";

import readingTime from "reading-time";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";

import { CssBaseline, Button, Snackbar, Fab } from "@material-ui/core";

import { auth } from "../../firebase";
import authentication from "../../services/authentication";
import appearance from "../../services/appearance";

import LaunchScreen from "../LaunchScreen";
import Bar from "../Navigation/Bar";
import Router from "../Router";
import DialogHost from "../DialogHost";
import { getUserById, updateUser, updateFirebaseUser } from '../../store/actions/UserAction';
import { useDispatch, useSelector } from "react-redux";

import '../../styles/app.scss';
import useHashConnect from "../HashConnect/useHashConnect";
import { AddRounded } from "@material-ui/icons";

let newTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#626476',
    },
    secondary: {
      main: '#FF6565',
    },
    black: {
      main: '#000000'
    }
  },
});

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    background: {
      default: "#121212",
    },
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#FF6565',
    },
    black: {
      main: '#000000'
    }
  },
});
const App = () => {

    const dispatch = useDispatch();

    const { connect, connectionStatus, hashconnect, initData, pairingData, walletMetadata } = useHashConnect();
    
    const user = useSelector(state => state.user.user);
    const userData = useSelector(state => state.user.user);
    const [ready, setReady] = useState(false);
    const [performingAction, setPerformingAction] = useState(false)
    const [theme, setTheme] = useState(!!localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark")
    const [roles, setRoles] = useState([])
    const [sideDrawerAnchorEl, setSideDrawerAnchorEl] = useState(null);

    const [aboutDialog, setAboutDialog] = useState({
      open: false,
    })
    const [newPostDialog, setNewPostDialog] = useState({
        open: false
    })
    const [userListDialog, setUserListDialog] = useState({
      open: false,
      followers: [],
      following: [],
      defaultTab: 0,
      visitUser: undefined
    })

    const [signInDialog, setSignInDialog] = useState({
        open: false,
    })
    const [confirmationDialog, setConfirmationDialog] = useState({
      open: false,
      success: true
    })
    const [contributorDialog, setContributorDialog] = useState({
      open: false,
      person: null
    })
    const [settingsDialog, setSettingsDialog] = useState({
        open: false,
    })
    const [deleteAccountDialog, setDeleteAccountDialog] = useState({
        open: false,
    })
    const [signOutDialog, setSignOutDialog] = useState({
        open: false,
    })
    const [snackbar, setSnackbar] = useState({
        autoHideDuration: 0,
        message: "",
        open: false,
    }) 
    
    // TODO: OLD Web3 code 
    // const walletConnector = useSelector((state) => state.blockchain.walletConnector)

    // useEffect(() => {
    //   if (walletConnector.cachedProvider) {
    //     walletConnector.connect().then((provider) => {
    //       const web3 = new Web3(provider)
    //       dispatch(connect(web3, provider));
    //       dispatch(setWeb3(new Web3(provider)));
    //     });
    //   }
    // }, [dispatch, walletConnector])
  

  // window.ethereum.on('accountsChanged', function (accounts) {
  //   console.log(accounts)
  //   const updatedUser = user;
  //   if (!!user && user.address !== accounts[0]) {
  //     updatedUser.address = accounts[0];
  //     saveUser(updatedUser);
  //   }
  // })

  const saveUser = async (updatedUser) => {
    return authentication
      .updateUser(updatedUser)
      .then((savedUser) => {
        // getCurrentUser();
        dispatch(updateUser(savedUser));
        // setCurrentUser(savedUser);
        return savedUser;
      })
      .catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          default:
            openSnackbar(message);
            return;
        }
      });
  }
  const resetState = (callback) => {
      setReady(true)
      setTheme(appearance.defaultTheme)
      dispatch(updateUser(null));
    //   dispatch(updateFirebaseUser(null));
      setRoles([])
      authentication.signOut();

      if (callback) {
          callback()
      }
  };

  const setAppTheme = (theme, callback) => {
    if (!theme) {
        setTheme(appearance.defaultTheme)
      
        if (callback) {
            callback()
        }

      return;
    }

    setTheme(theme)
      
    if (callback) {
        callback()
    }
  };

  const openUserListDialog = (followers, following, defaultTab, visitUser) => {
      setUserListDialog({
        open: true,
        defaultTab,
        followers,
        following,
        visitUser
      })
      return;
  }

  const openContributorDialog = (person) => {
    setContributorDialog({
      open: true,
      person: person
    })
  }

  const openConfirmationDialog = (success) => {
    setConfirmationDialog({
      open: true,
      success
    })
  }

  const openSignupDialog = () => {
    setSignInDialog({
      open: true,
      action: 'signup'
    })
  }

  const openDialog = (dialogId, callback) => {
    switch (dialogId){
    
        case "aboutDialog":
            setAboutDialog({
                open: true,
            })
            return;
        case "signUpDialog":
            setSignInDialog({
              open: true,
              action: 'signup'
            })
            return;

        case "signInDialog":
            setSignInDialog({
              open: true,
              action: "signin"
            })
            return;
        case "newPostDialog":
            setNewPostDialog({
                open: true,
            })
            return;
        case "settingsDialog":
            setSettingsDialog({
                open: true,
            })
            return;

        case "deleteAccountDialog":
            setDeleteAccountDialog({
                open: true,
            })
            return;

        case "signOutDialog":
            setSignOutDialog({
                open: true,
            })
            return;

    }
  };

  const closeDialog = (dialogId, callback) => {
    switch (dialogId){
    
        case "aboutDialog":
            setAboutDialog({
                open: false,
            })
            return;
        case "signUpDialog":
            setSignInDialog({
              open: false,
              action: "signin"
            })
            return;
        case "signInDialog":
            setSignInDialog({
                open: false,
            })
            return;

        case "confirmationDialog":
            setConfirmationDialog({
              ...confirmationDialog,
              open: false
            })
            return;
        case "contributorDialog":
            setContributorDialog({
                open: false,
                person: null
            })
            return;
        case "newPostDialog":
            setNewPostDialog({
                open: false,
            })
            return;

        case "userListDialog":
            setUserListDialog({
                open: false,
                followers: [],
                following: [],
                defaultTab: 0,
                visitUser: undefined
            })
            return;

        case "settingsDialog":
            setSettingsDialog({
                open: false,
            })
            return;

        case "deleteAccountDialog":
            setDeleteAccountDialog({
                open: false,
            })
            return;

        case "signOutDialog":
            setSignOutDialog({
                open: false,
            })
            return;
    }
  };

  const closeAllDialogs = (callback) => {
      setAboutDialog({
        open: false,
      })
      setSignInDialog({
        open: false,
      })
      setNewPostDialog({
        open: false,
      })
      setConfirmationDialog({
        ...confirmationDialog,
        open: false
      })
      setUserListDialog({
        open: false,
        followers: [],
        following: [],
        defaultTab: 0,
        visitUser: undefined
      })
      setSettingsDialog({
        open: false,
      })
      setDeleteAccountDialog({
        open: false,
      })
      setSignOutDialog({
        open: false,
      })
      if (callback) {
        callback()
    }
  };

    const deleteAccount = () => {
        setPerformingAction(true);
        authentication
            .deleteAccount()
            .then(() => {
                closeAllDialogs(() => {
                    openSnackbar("Deleted account");
                });
            })
            .catch((reason) => {
                const code = reason.code;
                const message = reason.message;

                switch (code) {
                    default:
                    openSnackbar(message);
                    return;
                }
            })
            .finally(() => {
                setPerformingAction(false);
            });
  };

    const signOut = () => {
        setPerformingAction(true);

        authentication
          .signOut()
          .then(() => {
            closeAllDialogs(() => {
              openSnackbar("Signed out");
            });
          })
          .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            switch (code) {
              default:
                openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            setPerformingAction(false);
          });
  };

    const openSnackbar = (message, autoHideDuration = 2, callback) => {
        setSnackbar(
            {
            autoHideDuration: readingTime(message).time * autoHideDuration,
            message,
            open: true,
        })
        if (callback && typeof callback === "function") {
            callback();
        }
    };

    const closeSnackbar = (clearMessage = false) => {
        setSnackbar({
            message: clearMessage ? "" : snackbar.message,
            open: false,
        });
    };
    
  useEffect(() => {
    auth.onAuthStateChanged(
      (authUser) => {
        // The user is not signed in or doesn’t have a user ID.
        if (!authUser || !authUser.uid) {
          resetState();
          return;
        }
        if (process.env.REACT_APP_TEST_USERS.split(", ").includes(authUser.email.toLowerCase())) {
          dispatch(updateFirebaseUser(authUser));
          authUser.getIdToken().then((idToken) => {
            dispatch(getUserById(authUser.uid, idToken))
          })

          authentication
          .getRoles()
          .then((value) => {
              setRoles(value ? value : []) 
          })
        } else {
          resetState();
          return;
        }
      }
      );
    }, [auth])
      
    useEffect(() => {
      if (user && !ready) {
        setReady(true)
      }
    }, [user])

    const openMenu = (event) => {    
      setSideDrawerAnchorEl(event.currentTarget);
    };

    return (
      <ThemeProvider theme={theme === "light" ? newTheme : darkTheme}>
        <CssBaseline />

        <div style={{ height: 'fit-content'}}>

        
        {/* <ErrorBoundary> */}
          {!ready && <LaunchScreen />}

          {ready && (
            <>
              <Router
                user={user}
                userData={userData}
                roles={roles}
                onContributorClick={openContributorDialog}
                onSignInClick={() => openDialog("signInDialog")}
                onNewPostClick={() => openDialog("newPostDialog")}
                onSettingsClick={() => openDialog("settingsDialog")}
                onConfirmation={(success) => openConfirmationDialog(success)}
                onUserListClick={openUserListDialog}
                openMenu={openMenu}
                theme={theme}
                bar={
                  <Bar
                    pairingData={pairingData}
                    connectionStatus={connectionStatus}
                    connectHashPack={connect}
                    performingAction={performingAction}
                    user={user}
                    userData={userData}
                    roles={roles}
                    onSignUpClick={() => openSignupDialog()}
                    onSignInClick={() => openDialog("signInDialog")}
                    onAboutClick={() => openDialog("aboutDialog")}
                    onSettingsClick={() => openDialog("settingsDialog")}
                    onSignOutClick={() => openDialog("signOutDialog")}
                    openMenu={openMenu}
                    sideDrawerAnchorEl={sideDrawerAnchorEl}
                    setSideDrawerAnchorEl={setSideDrawerAnchorEl}
                    theme={theme}
                    setTheme={setTheme}
                  />
                }
                openSnackbar={openSnackbar}
                dialogHost={
                  <DialogHost
                    performingAction={performingAction}
                    theme={theme}
                    user={user}
                    userData={userData}
                    openSnackbar={openSnackbar}
                    dialogs={{
                      aboutDialog: {
                        dialogProps: {
                          open: aboutDialog.open,

                          onClose: () => closeDialog("aboutDialog"),
                        },
                      },
                      contributorDialog: {
                        dialogProps: {
                          open: contributorDialog.open,
                          person: contributorDialog.person,
                          onClose: (callback) => {
                            closeDialog("contributorDialog");

                            if (callback && typeof callback === "function") {
                              callback();
                            }
                          },
                        }
                      },
                      confirmationDialog: {
                        dialogProps: {
                          open: confirmationDialog.open,

                          onClose: () => closeDialog("confirmationDialog"),
                          success: confirmationDialog.success
                        }
                      },

                      signInDialog: {
                        dialogProps: {
                          open: signInDialog.open,
                          action: signInDialog.action,

                          onClose: (callback) => {
                            closeDialog("signInDialog");

                            if (callback && typeof callback === "function") {
                              callback();
                            }
                          },
                        },
                      },
                      newPostDialog: {
                        dialogProps: {
                          open: newPostDialog.open,

                          onClose: () => closeDialog("newPostDialog"),
                        }
                      },
                      userListDialog: {
                        dialogProps: {
                          open: userListDialog.open,
                          followers: userListDialog.followers,
                          following: userListDialog.following,
                          defaultTab: userListDialog.defaultTab,
                          visitUser: userListDialog.visitUser,
                          
                          onClose: () => closeDialog("userListDialog"),
                        }
                      },

                      settingsDialog: {
                        dialogProps: {
                          open: settingsDialog.open,

                          onClose: () => closeDialog("settingsDialog"),
                        },

                        props: {
                          onDeleteAccountClick: () =>
                            openDialog("deleteAccountDialog"),
                        },
                      },

                      deleteAccountDialog: {
                        dialogProps: {
                          open: deleteAccountDialog.open,

                          onClose: () => closeDialog("deleteAccountDialog"),
                        },

                        props: {
                          deleteAccount: deleteAccount,
                        },
                      },

                      signOutDialog: {
                        dialogProps: {
                          open: signOutDialog.open,

                          onClose: () => closeDialog("signOutDialog"),
                        },

                        props: {
                          title: "Sign out?",
                          contentText:
                            "While signed out you are unable to manage your profile and conduct other activities that require you to be signed in.",
                          dismissiveAction: (
                            <Button
                              color="primary"
                              onClick={() => closeDialog("signOutDialog")}
                            >
                              Cancel
                            </Button>
                          ),
                          confirmingAction: (
                            <Button
                              color="primary"
                              disabled={performingAction}
                              variant="contained"
                              onClick={signOut}
                            >
                              Sign Out
                            </Button>
                          ),
                        },
                      },
                    }}
                  />
                }
              />
              <div style={{ position: 'fixed', bottom: '1rem', right: '1rem', zIndex: 1000 }}>
                <Fab aria-label="like" onClick={() => setNewPostDialog({ open: true })}>
                  <AddRounded />
                </Fab>
              </div>
              <Snackbar
                autoHideDuration={snackbar.autoHideDuration}
                message={snackbar.message}
                open={snackbar.open}
                onClose={closeSnackbar}
              />
            </>
          )}
        {/* </ErrorBoundary> */}
        </div>
      </ThemeProvider>
    ); 
}

export default App;
