import moment from "moment";

const initData = {
    currentPrices: [],
    lastUpdated: moment(),
    loadingPrices: true
}

const currentPriceReducer = ( state = initData, action ) => {
switch (action.type) {
    case 'UPDATE_CURRENT_PRICES':
        return {
            ...state,
            currentPrices: action.prices,
            lastUpdated: moment(),
            loadingPrices: false
        }
    default:
        return {
            ...state
        }
    }
}

export default currentPriceReducer;