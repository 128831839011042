import React, { Component, useState } from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogTitle,
  Tooltip,
  IconButton
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";
import { StyledProfileImg } from "./Contributors";

import * as s from "../../styles/globalStyles";

import '../../styles/contributorDialog.scss';

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },

  divider: {
    margin: "auto",
  },

  grid: {
    marginBottom: theme.spacing(2),
  },
});

const ContributorDialog = (props) => {

  const { innerWidth: width, innerHeight: height } = window;

  // Styling
  const { classes, person } = props;

  // Dialog Properties
  const { dialogProps } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...dialogProps}
      style={{ borderRadius: '16px' }}
    >
      <DialogTitle disableTypography>
        <Tooltip title="Close">
          <IconButton
            className={classes.closeButton}
            onClick={dialogProps.onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      
      {dialogProps.person &&
        <div style={{ margin: '0 1rem 1rem' }}>
          <div
            className="user"
            style={{
              margin: 'auto',
              textAlign: "center",
              fontFamily: 'raleway',
            }}
          >
            <StyledProfileImg style={{ width: '100px', height: '100px' }} src={`/config/images/${dialogProps.person.image}.jpeg`} />
            <s.TextDescription
              style={{
                textAlign: 'center',
                margin: '1rem auto',
                fontSize: '1.5rem',
                fontWeight: 600,
                whiteSpace: 'nowrap',
                fontFamily: 'Plus Jakarta Sans'
              }}
            >
              {dialogProps.person.name}
            </s.TextDescription>
          </div>

          <div className="description">
            {dialogProps.person.description}
          </div>

          <div className="media-links">
            {dialogProps.person.twitter &&
              <IconButton>
                <img
                  alt={"twitter"}
                  src={"/config/images/Twitter-small.png"}
                  onClick={() => window.open(dialogProps.person.twitter, "_blank")}
                />
              </IconButton>
            }
            {dialogProps.person.youtube &&
              <IconButton style={{ padding: '5px'}}>
                <img
                  alt={"youtube"}
                  src={"/config/images/youtube.png"}
                  onClick={() => window.open(dialogProps.person.youtube, "_blank")}
                />
              </IconButton>
            }
            {dialogProps.person.patreon &&
              <IconButton>
                <img
                  alt={"patreon"}
                  src={"/config/images/patreon.png"}
                  style={{ margin: '5px'}}
                  onClick={() => window.open(dialogProps.person.patreon, "_blank")}
                />
              </IconButton>
            }
            {dialogProps.person.web &&
              <IconButton>
                <img
                  alt={"web"}
                  style={{ margin: '3px'}}
                  src={"/config/images/web.png"}
                  onClick={() => window.open(dialogProps.person.web, "_blank")}
                />
              </IconButton>
            }
            {dialogProps.person.linktree &&
              <IconButton>
                <img
                  alt={"linktree"}
                  style={{ margin: '3px'}}
                  src={"/config/images/linktree.png"}
                  onClick={() => window.open(dialogProps.person.linktree, "_blank")}
                />
              </IconButton>
            }
            {dialogProps.person.discord &&
              <IconButton>
                <img
                  alt={"discord"}
                  style={{ margin: '3px', maxWidth: '77px', maxHeight: '50px' }}
                  src={"/config/images/discord-black.png"}
                  onClick={() => window.open(dialogProps.person.discord, "_blank")}
                />
              </IconButton>
            }
          </div>
        </div>
      }
    </Dialog>
  );
}

ContributorDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(ContributorDialog);
