import React from "react";

import styled from "styled-components";

import * as s from "../../styles/globalStyles";
import '../../styles/about.scss';


export const StyledProfileImg = styled.img`
  margin-top: 10px;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 100%;
  width: 120px;
  height: 120px;
  @media (min-width: 900px) {
    width: 150px;
    height: 150px;
  }
  transition: width 0.5s;
`;

export const DesktopOnlyBackground = styled.img`
    @media (max-width: 800px) {
        // display: none;
    };
`;
const Contributors = (props) => {
  const { innerWidth: width, innerHeight: height } = window;

  const { onContributorClick } = props;

  const contributors = [
    {
        name: 'King Solomon',
        image: 'SOLOMONJPEG',
        description: 'I have been providing research and insights into digital assets, Blockchain, and Distributed Ledger Technology since 2018. My focus has and will continue to be shining a light on the digital transformation that is upon us with content rooted in facts not hype. As founder of The Lighthouse Report I’m excited to contribute to expanding the reach of real research and content provision in the digital assets space in a format that is engaging and welcoming for our audience.',
        twitter: 'https://twitter.com/XRP_OWL',
        youtube: 'https://www.youtube.com/c/KingSolomon123',
        patreon: 'https://www.patreon.com/HailtotheKing/posts'
    },
    {
        name: 'Crypto Eri',
        image: 'ERI',
        description: 'As an early digital adopter of Internet solutions, Eri founded an online buy-sell-trade platform in 1997 for secondary market collectibles, specializing in limited edition fine art prints, sculpture and originals with a focus on publishing houses like Greenwich Workshop, Hadley, Thomas Kinkade and others. Through an acqui-hire of her business in 2000, she joined a west coast team that delivered high resolution digital images on-demand, globally, to galleries and frame shops through proprietary software which went public in 2001 through a reverse merger. Upon later relocating to Tokyo, Eri entered the collectibles market again, establishing an export channel for vintage Japanese artisan goods, curated from the Edo, Meiji, Taisho and Showa periods. On the discovery of Bitcoin in 2013, the rabbit hole began a new chapter of content creation, covering the entire blockchain space that has continued full time since 2017, using multiple social media platforms. ',
        twitter: 'https://twitter.com/sentosumosaba',
        youtube: 'https://www.youtube.com/user/erichanintokyo'
    },
    {
        name: 'Jeremy Hogan',
        image: 'HOGAN',
        description: 'Attorney Jeremy Hogan has over twenty years’ experience in the legal field and comments on crypto related legal issues on his Youtube channel “Legal Briefs” and on Twitter @attorneyjeremy1.',
        twitter: 'https://twitter.com/attorneyjeremy1',
        youtube: 'https://www.youtube.com/c/LegalBriefs'
    },    
    {
      name: 'Darren Moore JR.',
      image: 'DARREN',
      description: 'I create content by providing evidence that digital assets and blockchain technologies will be integrated into the global economy.  I discuss the benefits as well as the faults of how this technology can be applied.  I describe the macro landscape and what is on the horizon.',
      twitter: 'https://twitter.com/Fame21Moore',
      youtube: 'https://www.youtube.com/c/DarrenMooreJr'
    },
    {
      name: 'Santiment',
      image: 'SANTIMENT',
      description: 'Santiment is a comprehensive market intelligence platform for cryptocurrencies, focusing on clean and reliable data feeds, low-latency signals and custom market analysis. Leveraging on-chain, social, development and other data sources, Santiment has developed dozens of specialized tools, indicators and strategies that help users identify, contextualize and react to market events and anomalies.',
      web: 'https://santiment.net/',
      twitter: 'https://twitter.com/santimentfeed',
      youtube: 'https://www.youtube.com/c/SantimentNetwork',
      discord: 'https://discord.gg/2cknXq5jbH'
    },
    {
      name: 'Sadaf Jadran',
      image: 'SADAF',
      description: 'I’m a passionate strategist with a strong focus on emerging digital trends in the age of digital disruption. I’ve been navigating the space since 2017 with my initial investments being Ethereum and XRP. With over a decade experience in traditional Finance, I’ve been focusing on disruptors within Web3 including DeFi & NFTs and their role in our new economic frontier. My vision is to bring education, awareness and adoption to blockchain technology and the virtual economy.',
      twitter: 'https://twitter.com/SadafJadran'
    },
    {
      name: 'Tokenicer',
      image: 'TOKENICER',
      description: 'My coverage of content ranges anywhere from relaxed discussions about updates & news around the general markets and utility networks, all the way to in depth coverage of ecosystems that have quietly laid the core infrastructure connections of a future technologically globalized economy.',
      linktree: 'https://linktr.ee/tokenicer'
    },
    {
      name: 'Citizen of the future',
      image: 'CITIZEN',
      description: 'I’m Citizen of The Future. I focus my content on DLT & 4IR technology that’s revolutionizing industries across the globe. I share research on digital assets with utility, regulated fintech, trade finance, and banking documents. My mission is to give other citizens a chance to take part in the 4th industrial revolution.',
      twitter: 'https://twitter.com/UtilityTheory',
      youtube: 'https://www.youtube.com/channel/UCvfL83LHrhwfbHPSWW12NLA'
    },
    {
      name: 'PreIPO club',
      image: 'PREIPO',
      description: 'PreIPO helps your followers become more knowledgeable about the private markets, and how to invest in private companies in digital assets and blockchain.',
      twitter: 'https://twitter.com/ipo_club',
      linkedin: 'https://www.linkedin.com/company/pre-ipo-club',
      web: 'https://www.preipo.club/clubletters'
    },
    {
      name: 'ELD Trades',
      image: 'ELD',
      description: `I have been an active trader and investor for over six years. Originally specializing in equities and commodities, I transitioned to cryptocurrency after discovering it better complimented my style of trading. Having experienced three Bitcoin cycles, the lessons learned have been great in measure. These lessons have been applied to form both a technical and psychological approach to the markets with a showcase of consistent wins over trades in the long term. Alongside trading, my crypto hedge fund has outperformed the market and educated my community. Current market conditions are not easy to navigate given the risk and unpredictable nature of this space, but my experience can teach you to how to navigate through volatility by better understanding the market.`,
      twitter: 'https://twitter.com/ELDTrades',
      youtube: 'https://www.youtube.com/channel/UCkMPQQKZxRQiQVAID6yTYkQ'
    },
  ]

  return (
    <s.Container
      jc={"center"}
      ai={"center"}
      style={{
        width: "100%",
      }}
    >
      <div
        className='contibutor-list'
        style={{ 
            width: '100%',
            maxWidth: '1150px',
            position: 'relative',
        }}
      >
        <div
          className="content"
          style={{ height: 'fit-content' }}
        >
          <s.Container fd="row" jc={"center"} ai={"center"} >
            {contributors.map(member => {
              return (
                <div
                  className="user"
                  onClick={() => onContributorClick(member)}
                  style={{
                    margin: width > 600 ? '20px 40px' : '20px',
                    width: 'fit-content',
                    textAlign: "center",
                    fontFamily: 'Plus Jakarta Sans',
                  }}
                >
                  <StyledProfileImg style={{ cursor: 'pointer' }} src={`/config/images/${member.image}.jpeg`} />
                  <s.TextDescription
                    style={{
                      marginBottom: '10px',
                      fontSize: '.8rem',
                      overflow: 'visible',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {member.name}
                  </s.TextDescription>
                </div>
              )
            })}
          </s.Container>
        </div>
      </div>
    </s.Container>
  )
}

export default Contributors;
