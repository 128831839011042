import React, { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  IconButton
} from "@material-ui/core";

import { StyledLogoIcon } from "./Header";

import '../../styles/navbar.scss';
import { useNavigate } from "react-router";
import usePrevious from "../hooks/usePrevious";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { MenuRounded, AddRounded, SearchRounded, MoreRounded, VerifiedUserOutlined, AccountCircleOutlined, HomeOutlined, PublicOutlined } from "@material-ui/icons";
import PostDialog from "../PostDialog/PostDialog";
import { useLocation } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
    footer: {
      top: 'auto',
      bottom: 0,
    },
    fabButton: {
      position: 'absolute',
      zIndex: 1,
    //   top: -30,
      left: 0,
      right: 0,
      margin: '0 auto',
    },
  }));
const Footer = (props) => {
  
  const classes = useStyles();
  const location = useLocation()

  // Properties
  const { postDiaglogOpen, onNewPostClick, onPostDialogClose } = props;

  const user = useSelector(state => state.user.user);

  const navigate = useNavigate();
  // Events
  const {

  } = props;

  if (!user) {
      return null;
  }
  return (
    <AppBar position="fixed" className={'footer'} style={{ backgroundColor: "var(--accent-background)"}}>
      <Toolbar style={{ minHeight: '74px' }}>
        <IconButton
            onClick={() => navigate(location.pathname.includes("user") ? `${user.firebaseId}` : `/user/${user.firebaseId}`)}
            color="primary"
            aria-label="Account"
        >
            <AccountCircleOutlined />
        </IconButton>
        <IconButton
            onClick={() => navigate("/search")}
            color="primary"
            aria-label="Search"
        >
            <SearchRounded />
        </IconButton>
        <IconButton color="primary">
            <StyledLogoIcon style={{}} onClick={onNewPostClick} src={"/config/images/mobile_logo.png"} />
        </IconButton>
        <IconButton color="primary">
            <PublicOutlined />
        </IconButton>
        <IconButton
            onClick={() => navigate('/')}
            color="primary"
            aria-label="Home"
        >
            <HomeOutlined />
        </IconButton>
      </Toolbar>
      <PostDialog open={postDiaglogOpen} onClose={onPostDialogClose}  />
    </AppBar>
  );
}

export default Footer;
