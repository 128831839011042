import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import * as serviceWorker from "./serviceWorker";
import "typeface-roboto";
import "./index.css";
import App from "./components/App/App";
import configureStore from "./store/store";
import { Provider } from "react-redux";
import store from "./store/store";
import { MoralisProvider } from "react-moralis";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
});
ReactDOM.render(
  <Provider store={store} >
    <MoralisProvider appId="eVvZNp83iLpuApTm16oOuapNUPvd5jCe8clHfZ6F" serverUrl="https://wwusrnsuha79.usemoralis.com:2053/server">
      <App />
    </MoralisProvider>
  </Provider>, document.getElementById("root"));
serviceWorker.register();
