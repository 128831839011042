import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { Close as CloseIcon, Twitter } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  socialButton: {
    height: '35px'
  }
}));

function AboutDialog(props) {
  const classes = useStyles();
  const dialogProps = props.dialogProps;
  const user = props.user;
  const version = process.env.REACT_APP_VERSION;

  if (!user && !version) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="xs" {...dialogProps}>
      <DialogTitle disableTypography>
        <Typography variant="h6">
          About {process.env.REACT_APP_TITLE}
        </Typography>

        <Tooltip title="Close">
          <IconButton
            className={classes.closeButton}
            onClick={dialogProps.onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <List disablePadding>
          {version && (
            <ListItem>
              <ListItemText primary="Gen3 Version" secondary={version} />
            </ListItem>
          )}

          
          <ListItem style={{ textAlign: 'center' }}>
            <ListItemText
              primary={<Typography variant="h6">Stay Connected</Typography>}
              secondary={
                <div className='flex' style={{ justifyContent: 'center', marginTop: '1.4rem' }}>
                  <Button
                    className={classes.socialButton}
                    size="small" 
                    variant="outlined"
                    color="default"
                    onClick={() => window.open('https://twitter.com/GenfinityIO', "_blank")}
                    startIcon={<Twitter />}
                    style={{ marginRight: '1rem' }}
                  >
                    Twitter
                  </Button>
                  <Button
                    className={classes.socialButton}
                    size="small" 
                    variant="outlined"
                    color="default"
                    onClick={() => window.open('https://discord.gg/genfinity', "_blank")}
                    startIcon={<img
                      alt={"discord"}
                      style={{ margin: '3px', height: '1.3rem'  }}
                      src={"/config/images/discord-black.png"}
                    />}
                  >
                    Discord
                  </Button>
                </div>
              }
            />
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
}

AboutDialog.propTypes = {
  dialogProps: PropTypes.object.isRequired,
  user: PropTypes.object,
};

export default AboutDialog;
