import React, { useState } from "react";

import AuthProviderList from "../Auth/AuthProviderList";
import authentication from "../../services/authentication";
import { updateUser, createUser } from "../../store/actions/UserAction";
import { useDispatch } from "react-redux";


import {
  IconButton,
  Grid,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import '../../styles/lighthouse.scss'
import '../../styles/signinForm.scss'
import validate from "validate.js";
import constraints from "../../data/constraints";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";


const SignUpForm = (props) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { changeAuthAction, openSnackbar } = props;

  const { innerWidth: width, innerHeight: height } = window;
  const [performingAction, setPerformingAction] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressConfirmation, setEmailAddressConfirmation] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [errors, setErrors] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const signUp = () => {
    if (process.env.REACT_APP_TEST_USERS.split(", ").includes(emailAddress.toLowerCase())) {
      const formErrors = validate(
        {
          emailAddress: emailAddress,
          emailAddressConfirmation: emailAddressConfirmation,
          password: password,
          passwordConfirmation: passwordConfirmation,
        },
        {
          emailAddress: constraints.emailAddress,
          emailAddressConfirmation: constraints.emailAddressConfirmation,
          password: constraints.password,
          passwordConfirmation: constraints.passwordConfirmation,
        }
      );

      if (formErrors) {
        setErrors(formErrors);
      } else {
        setPerformingAction(true);
        setErrors(null);
        authentication
          .signUpWithEmailAddressAndPassword(emailAddress, password)
          .then((newUser) => {
            props.openSnackbar(
              `Signed in as ${newUser.displayName || newUser.email}`
            );
            newUser.getIdToken().then((idToken) => {
              dispatch(createUser(idToken));
            })
            navigate(`/user/${newUser.firebaseId}`);

          })
          .catch((reason) => {
            authentication
              .signOut();
            const code = reason.code;
            const message = reason.message;
            setPerformingAction(false);

            switch (code) {
              case "auth/email-already-in-use":
              case "auth/invalid-email":
              case "auth/operation-not-allowed":
              case "auth/weak-password":
                props.openSnackbar(message);
                return;

              default:
                props.openSnackbar(message);
                return;
            }
          })
          .finally(() => {
            setPerformingAction(false);
            // window.location.href = "https://www.lighthouse.genfinity.io/";
          });
      }
    } else {
      props.openSnackbar("Email address is not Whitelisted for alpha testing.");
    }
  };

  const signInWithAuthProvider = (provider) => {
    setPerformingAction(true);
    authentication
      .signInWithAuthProvider(provider)
      .then((newUser) => {
        if (newUser && process.env.REACT_APP_TEST_USERS.split(", ").includes(newUser.email.toLowerCase())) {
          dispatch(updateUser(newUser))
          props.openSnackbar(
            `Signed in as ${newUser.displayName || newUser.email}`
          );
          navigate(`/user/${newUser.firebaseId}`);
        } else {
          props.openSnackbar("Email address is not Whitelisted for alpha testing.");
          authentication.signOut();
        }
      })
      .catch((reason) => {
        authentication
          .signOut();
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          case "auth/account-exists-with-different-credential":
          case "auth/auth-domain-config-required":
          case "auth/cancelled-popup-request":
          case "auth/operation-not-allowed":
          case "auth/operation-not-supported-in-this-environment":
          case "auth/popup-blocked":
          case "auth/popup-closed-by-user":
          case "auth/unauthorized-domain":
            props.openSnackbar(message);
            return;

          default:
            props.openSnackbar(message);
            return;
        }
      })
      .finally(() => {
        setPerformingAction(false);
        // window.location.href = "https://www.lighthouse.genfinity.io/";
      });
  };

  const handleKeyPress = (event) => {

    if (
      !emailAddress ||
      !emailAddressConfirmation ||
      !password ||
      !passwordConfirmation
    ) {
      return;
    }

    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === "Enter") {
      signUp();
    }
  };

  const handleExited = () => {
    setPerformingAction(false);
    setEmailAddress("");
    setEmailAddressConfirmation("");
    setPassword("");
    setPasswordConfirmation("");
    setErrors(null);
  };

  const handleEmailAddressChange = (event) => {
    setEmailAddress(event.target.value)
  };

  const handleEmailAddressConfirmationChange = (event) => {
    setEmailAddressConfirmation(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordConfirmationChange = (event) => {
    setPasswordConfirmation(event.target.value);
  };

  return (
    <div className="lighthouse-login-form">
      <div className="lighthouse-login-content">
        <div
          style={{
            fontSize: '1rem',
            lineHeight: '1rem',
            marginBottom: '3rem',
            textTransform: 'none'
          }}
        >
          Sign up to the <span style={{ color: '#FF6565' }}>LightHouse Report</span>
        </div>
          <Grid container direction="column" spacing={2}  style={{ width: '100%', margin: 'auto', display: 'flex', flexDirection: 'column'}}>
              <TextField
                autoComplete="email"
                disabled={performingAction}
                error={!!(errors && errors.emailAddress)}
                fullWidth
                helperText={
                  errors && errors.emailAddress ? errors.emailAddress[0] : ""
                }
                label="E-mail address"
                placeholder="john@doe.com"
                required
                type="email"
                value={emailAddress}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={handleEmailAddressChange}
                style={{ marginBottom: '1rem', flexGrow: 1 }}
              />

              <TextField
                autoComplete="email"
                disabled={performingAction}
                error={!!(errors && errors.emailAddressConfirmation)}
                fullWidth
                helperText={
                  errors && errors.emailAddressConfirmation
                    ? errors.emailAddressConfirmation[0]
                    : ""
                }
                label="E-mail address confirmation"
                placeholder="john@doe.com"
                required
                type="email"
                value={emailAddressConfirmation}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={handleEmailAddressConfirmationChange}
                style={{ marginBottom: '1rem', flexGrow: 1 }}
              />

              <TextField
                autoComplete="new-password"
                disabled={performingAction}
                error={!!(errors && errors.password)}
                fullWidth
                helperText={
                  errors && errors.password ? errors.password[0] : ""
                }
                label="Password"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                required
                type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                value={password}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={handlePasswordChange}
                style={{ marginBottom: '1rem', flexGrow: 1 }}
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <TextField
                autoComplete="password"
                disabled={performingAction}
                error={!!(errors && errors.passwordConfirmation)}
                fullWidth
                helperText={
                  errors && errors.passwordConfirmation
                    ? errors.passwordConfirmation[0]
                    : ""
                }
                label="Password confirmation"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                required
                type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                value={passwordConfirmation}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={handlePasswordConfirmationChange}
                style={{ flexGrow: 1, marginBottom: '2rem' }}
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
          </Grid>
        <Button
          style={{ backgroundColor: '#0385F0', color: 'white', width: '100%', borderRadius: '1.5rem' }}
          disabled={!emailAddress || performingAction}
          variant="contained"
          onClick={() => signUp()}
        >
          Sign Up
        </Button> 
      </div>

      <AuthProviderList
        gutterBottom
        performingAction={performingAction}
        onAuthProviderClick={signInWithAuthProvider}
      />
      <div style={{ textAlign: 'center', padding: '0 0 1rem 0', fontSize: '.8rem', lineHeight: '1rem', textTransform: 'none' }}>or</div>

      <div  style={{ color: '#FF6565', cursor: 'pointer', textAlign: 'center', fontWeight: 700 }} onClick={() => changeAuthAction()}>Sign In</div>

    </div>
  )
}

export default SignUpForm;
