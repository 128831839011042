import React, { Component, useState } from "react";
import firebase, { analytics, auth, firestore, storage } from "../../firebase";

import PropTypes from "prop-types";

import { Dialog, DialogTitle, Box, Badge, Tooltip, Fab, Fade, CircularProgress, Avatar, Button, TextField } from "@material-ui/core";
import { CloseCircleOutline } from "mdi-material-ui";
import { withStyles } from "@material-ui/core/styles";
import { AddAPhotoRounded, CloudUploadRounded, PhotoRounded } from "@material-ui/icons";

import '../../styles/postDialog.scss';

import posts from "../../services/posts";
import { useSelector, useDispatch } from "react-redux";
import { getUserById } from "../../store/actions/UserAction";

const styles = (theme) => ({
    dialogContent: {
      paddingTop: theme.spacing(2),
    },
  
    badge: {
      top: theme.spacing(2),
      right: -theme.spacing(2),
    },
  
    loadingBadge: {
      top: "50%",
      right: "50%",
    },
  
    avatar: {
      marginRight: "auto",
      marginLeft: "auto",
  
      width: '300px',
      height: '300px'
    },
  
    nameInitials: {
      cursor: "default",
    },
  
    personIcon: {
      fontSize: theme.spacing(7),
    },
  
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),
  
      minHeight: "initial",
    },
  });
  
const PostDialog = (props) => {
    const { open, onClose, dialogProps } = props;
    const { classes, userData } = props;

    const dispatch = useDispatch()
  
    const user = auth.currentUser;
    const loggedInUser = useSelector(state => state.user.user);

    const [loadingImage, setLoadingImage] = useState(false)
    const [image, setImage] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [firebaseImageUrl, setFirebaseImageUrl] = useState(null)
    const [performingAction, setPerformingAction] = useState(false);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [uploadingPost, setUploadingPost] = useState(false);

    const clearForm = () => {
      setFirebaseImageUrl(setFirebaseImageUrl);
      setPerformingAction(false);
      setUploadingPost(false);
      setDescription('')
      setTitle('')
      setImage(null)
      setImageUrl(null)
      setLoadingImage(false);
    }

    const closeForm = () => {
      clearForm()
      dialogProps.onClose()
    }

    const uploadPost = () => {
      const post = {
        imageUrl: firebaseImageUrl,
        description,
        title,
        userId: loggedInUser.id
      }
      setUploadingPost(true);
      posts
        .uploadPost(post)
        .then((post) => {
            console.log(post)
            const currentUser = auth.currentUser;

            currentUser.getIdToken().then((idToken) => {
              dispatch(getUserById(currentUser.uid, idToken))
            });
            clearForm();
            dialogProps.onClose();
        })
        .catch((reason) => {
            const code = reason.code;
            const message = reason.message;

            clearForm()
            dialogProps.onClose();
            
            switch (code) {
                default:
                  props.openSnackbar(message);
                return;
            }
        })
        .finally(() => {
          setUploadingPost(false);
        });
    }
    
    const uploadImage = (newImage) => {
    
        if (!newImage) {
          return;
        }
    
        setPerformingAction(true);
        setLoadingImage(true);
        posts
            .uploadImage(newImage)
            .then((firebaseUrl) => {
                setFirebaseImageUrl(firebaseUrl);
                console.log(firebaseUrl)
            })
            .catch((reason) => {
                const code = reason.code;
                const message = reason.message;
                console.log(reason)

                switch (code) {
                    default:
                      props.openSnackbar(message);
                    return;
                }
            })
            .finally(() => {
                setPerformingAction(false);
                setLoadingImage(false);
            });
    };

    const handleDescriptionChange = (event) => {
      if (!event) {
        return;
      }

      setDescription(event.target.value)
    }

    const handleTitleChange = (event) => {
      if (!event) {
        return;
      }

      setTitle(event.target.value)
    }

    const handleImageChange = (event) => {
        if (!event) {
          return;
        }
    
        const files = event.target.files;
    
        if (!files) {
          return;
        }
    
        const image = files[0];
    
        if (!image) {
          return;
        }
    
        const fileTypes = [
          "image/gif",
          "image/jpeg",
          "image/png",
          "image/webp",
          "image/svg+xml",
        ];
    
        if (!fileTypes.includes(image.type)) {
          return;
        }
    
        if (image.size > 20 * 1024 * 1024) {
          return;
        }

        setImage(image);
        setImageUrl(URL.createObjectURL(image));
        uploadImage(image)
    };

    const removeAvatar = () => {
        setImage(null);
        setImageUrl(null);
        setFirebaseImageUrl(null);
    }

    console.log("dialogProps")
    console.log(dialogProps)

    return (
        <Dialog className={'post-dialog-paper'}  {...dialogProps} onClose={onClose} >
            <div  className={'post-dialog'}>
                <DialogTitle style={{ }} >New Post</DialogTitle>
                
                <Box textAlign="center" style={{ padding: '0px 30px 20px'}}>

                    <Box mb={1.5}>
                    {image && imageUrl && (
                      <Badge
                        classes={{ badge: classes.badge }}
                        badgeContent={
                          <Tooltip title="Remove">
                            <Fab
                              classes={{ sizeSmall: classes.small }}
                              color="secondary"
                              disabled={performingAction}
                              size="small"
                              onClick={removeAvatar}
                            >
                              <CloseCircleOutline fontSize="small" />
                            </Fab>
                          </Tooltip>
                        }
                      >
                        {loadingImage && (
                          <Badge
                            classes={{ badge: classes.loadingBadge }}
                            badgeContent={
                              <Fade
                                style={{ transitionDelay: "1s" }}
                                in={loadingImage}
                                unmountOnExit
                              >
                                <CircularProgress size={120} thickness={1.8} />
                              </Fade>
                            }
                          >
                            <img
                              className={classes.avatar}
                              alt="Avatar"
                              src={imageUrl}
                            />
                          </Badge>
                        )}

                        {!loadingImage && (
                          <img
                            className={classes.avatar}
                            alt="Avatar"
                            src={imageUrl}
                          />
                        )}
                      </Badge>
                    )}

                    {!image && !imageUrl && (
                      <>
                          <>
                            {loadingImage && (
                              <Badge
                                classes={{ badge: classes.loadingBadge }}
                                badgeContent={
                                  <Fade
                                    style={{ transitionDelay: "1s" }}
                                    in={loadingImage}
                                    unmountOnExit
                                  >
                                    <CircularProgress
                                      size={120}
                                      thickness={1.8}
                                    />
                                  </Fade>
                                }
                              >
                                <div>
                                  Upload
                                </div>
                              </Badge>
                            )}

                            {!image && !imageUrl && (
                              <div style={{ justifyContent: 'center', margin: 'auto', width: '300px', height: '300px', border: '1px solid black', borderRadius: '1rem', display: 'flex'}}>
                               <input
                                  id="avatar-input"
                                  type="file"
                                  hidden
                                  accept="image/*"
                                  onChange={handleImageChange}
                                />
                                <label htmlFor="avatar-input" style={{alignSelf: 'center', cursor: 'pointer' }}>
                                  <AddAPhotoRounded style={{ fontSize: '3rem' }} />
                                </label>
                              </div>
                            )}
                          </>
                      </>
                    )}
                  </Box>

                  {/* {image && imageUrl && (
                    <Button
                      color="primary"
                      disabled={performingAction}
                      startIcon={<CloudUploadRounded />}
                      variant="contained"
                      onClick={uploadImage}
                    >
                      Upload
                    </Button>
                  )} */}

                  {/* {!image && !imageUrl && (
                    <>
                      <input
                        id="avatar-input"
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleImageChange}
                      />

                      <label htmlFor="avatar-input">
                        <Button
                          color="secondary"
                          component="span"
                          disabled={performingAction}
                          startIcon={<PhotoRounded />}
                          variant="contained"
                        >
                          Choose...
                        </Button>
                      </label>
                    </>
                  )} */}

                <div className={'post-fields'}>
                    <TextField
                        id="title-field"
                        label="Title"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={title}
                        onChange={handleTitleChange}
                        variant="outlined"
                    />

                    <TextField
                      id="description-field"
                      label="Description"
                      multiline
                      rows={4}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      value={description}
                      onChange={handleDescriptionChange}
                      variant="outlined"
                    />
                </div>
                <div className='dialog-buttons'>
                <Button
                  className='cancel-button'
                  component="span"
                  variant="outlined"
                  onClick={() => closeForm()}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  component="span"
                  disabled={!title || !description || !firebaseImageUrl}
                  startIcon={<PhotoRounded />}
                  variant="contained"
                  onClick={() => uploadPost()}
                >
                  Post
                </Button>

                </div>
                </Box>
            </div>
        </Dialog>
    );
}

PostDialog.propTypes = {
  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Properties
  title: PropTypes.string,
  content: PropTypes.element.isRequired,
};

export default withStyles(styles)(PostDialog);
