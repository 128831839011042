import { HashConnect } from 'hashconnect';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import authentication from '../../services/authentication';
import { setLoadingCurrentUser, getCurrentUser, updateUser } from '../../store/actions/UserAction';

const useHashConnect = (user) => {

  const appMetadata = {
    name: "Gen3",
    description: "Gen3 by Genfinity",
    icon: "/config/images/small_logo.png"
  }

  const dispatch = useDispatch();

  const [hashconnect, setHashconnect] = useState(new HashConnect())
  const [initData, setInitData] = useState([]);
  const [walletMetadata, setWalletMetadata] = useState([]);
  const [pairingData, setPairingData] = useState();
  const [connectionStatus, setConnectionStatus] = useState()
  
  let saveData = {
    topic: "",
    pairingString: "",
    privateKey: "",
    pairedWalletData: null,
    pairedAccounts: []
  }

  const saveUser = async (updatedUser) => {
    return authentication
      .updateUser(updatedUser)
      .then((savedUser) => {
        dispatch(updateUser(savedUser))
        return savedUser;
      })
      .catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        // switch (code) {
        //   default:
        //     openSnackbar(message);
        //     return;
        // }
      });
  }

  // useEffect(() => {
  //   if (!!user) {
  //     setCurrentUser(user);
  //   }
  // }, [user]);

  const setupEvents = async () => {
    hashconnect.foundExtensionEvent.once((foundWalletMetadata) => {
      setWalletMetadata([...walletMetadata, foundWalletMetadata])
    });
    hashconnect.pairingEvent.once((data) => {
      setPairingData(data)
      updateHBarAddress(data.accountIds[0])
      if (!!user && !!data.accountIds[0] && user.hbarAddress !== data.accountIds[0]) {
        const updatedUser = user;
        updatedUser.hbarAddress = data.accountIds[0];
        saveUser(updatedUser);
      }
    });
    hashconnect.connectionStatusChangeEvent.once((status) => {
      console.log(status);
      setConnectionStatus(status)
    });
  }

  const initHashConnect = async () => {
    let data = await hashconnect.init(appMetadata, "mainnet", false);
    setInitData(data);
  }

  const updateHBarAddress = (address) => {

    if (!!user) {
      const updatedUser = user;
      updatedUser.hederaAddress = address;
      return authentication
        .updateUser(updatedUser)
        .then((savedUser) => {
          dispatch(updateUser(savedUser));
          // setCurrentUser(savedUser);
          return savedUser;
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;
  
          switch (code) {
            default:
              // openSnackbar(message);
              return;
          }
        });
    }
  }

  useEffect(() => {
    setupEvents()
    initHashConnect();

  }, []);

  useEffect(() => {
    console.log(connectionStatus);
    console.log(pairingData);
    console.log(walletMetadata);

  }, [connectionStatus, pairingData, walletMetadata])
  // useEffect(() => {

  //   if (!!initData && connectionStatus === 'Connected' && walletMetadata.length > 0) {
  //     initHashConnect();
  //   }
  // }, [initData, connectionStatus, walletMetadata])

  const connect = () => {
    hashconnect.connectToLocalWallet(initData.pairingString, walletMetadata[0]);
  };
  
  return { connect, connectionStatus, hashconnect, initData, pairingData, walletMetadata }

//   hashconnect.foundExtensionEvent.once((metadata) => {
//     console.log(metadata)
//   })
//   const initHashconnect = async () => {
//     //create the hashconnect instance
//     if (!hashconnect) {
//       setHashconnect(new HashConnect());
//     }

//     if(!loadLocalData()){
//         //first init and store the private for later
//         let initData = await hashconnect.init(appMetadata);
//         saveData.privateKey = initData.privKey;

//         //then connect, storing the new topic for later
//         const state = await hashconnect.connect();
//         saveData.topic = state.topic;
        
//         //generate a pairing string, which you can display and generate a QR code from
//         saveData.pairingString = hashconnect.generatePairingString(state, "testnet", true);
        
//         //find any supported local wallets
//         hashconnect.findLocalWallets();
//     }
//     else {
//         //use loaded data for initialization + connection
//         await hashconnect.init(appMetadata, saveData.privateKey);
//         await hashconnect.connect(saveData.topic, saveData.pairedWalletData);
//     }
// }
//   const loadLocalData = () => {
//     let foundData = localStorage.getItem("hashconnectData");

//     if(foundData){
//         saveData = JSON.parse(foundData);
//         return true;
//     }
//     else
//         return false;
//   }

//   useEffect(() => {
//     initHashconnect()
//   }, []);

//   const connect = () => {
//     hashconnect.connectToLocalWallet(saveData.topic, saveData.pairedWalletData);
//   };
  
//   return { connect, connectionStatus, hashconnect, initData, pairingData, walletMetadata }
}

export default useHashConnect;