import React from "react";

import styled from "styled-components";

import * as s from "../../styles/globalStyles";
import '../../styles/about.scss';


export const StyledProfileImg = styled.img`
  margin-top: 10px;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 100%;
  width: 100px;
  @media (min-width: 900px) {
    width: 150px;

    filter: grayscale(100%);

    :hover {
        filter: grayscale(0%);
    }

  }
  @media (min-width: 1000px) {
    width: 170px;
  }
  transition: width 0.5s;
`;

export const DesktopOnlyBackground = styled.img`
    @media (max-width: 800px) {
        // display: none;
    };
`;
const TeamList = (props) => {

    const { onContributorClick } = props;
    const { innerWidth: width, innerHeight: height } = window;

    const founders = [
        {
            name: 'Ryan Solomon',
            position: 'CEO & FOUNDER',
            image: 'SOLOMONJPEG',
            description: 'Ryan Solomon is Founder and CEO at Genfinity LLC.  He facilitates business relations, product oversight, and coordinates strategic delivery of all company objectives. Ryan’s vision for Genfinity is to provide a product driven ecosystem of connectivity, value, and transparency in the digital assets space.  Ryan has over five years of experience in the Blockchain/DLT space and a passion for providing real value to his community through due diligence in research and non-narrative driven content through his King Solomon platform.  Prior to Genfinity, Ryan spent nine years as a project manager in the nuclear and oil and gas industries. As a Navy veteran Ryan deployed in 2011, attained the Navy Good Conduct Medal in 2012, and scaled in rank from E-1 to E-5 prior to his four-year honorable contract fulfillment.',
            twitter: 'https://twitter.com/XRP_OWL'
        },
        {
            name: 'Carola Schmidt',
            position: 'COO & FOUNDER',
            image: 'CAROLA',
            description: 'Carola Schmidt is Co-Founder and COO at Genfinity LLC.  She is responsible for overseeing operations and applying strategies to implement Genfinity’s vision, as well as ensuring the smooth running of internal affairs.  Carola has a background in law, administration, and the arts.  After being admitted to practice law in 2003 and working in legal policy for some years, she was called to the arts and is now passionate about making blockchain technology and the virtual Creator Economy more accessible and relatable to all Creators.',
            twitter: 'https://twitter.com/Xrp_Muse '
        },
        {
            name: 'Valor Kopeny',
            position: 'CCO & FOUNDER',
            image: 'VALOR',
            description: 'Valor Kopeny is Co-Founder and CCO at Genfinity LLC.  He is responsible for overseeing the strategy and implementation of Genfinity’s creative goals, determining the visual elements of the brand and product lines.  With a background in business, entrepreneurship and the arts, Valor previously held a successful career as a Media Director.  His goal is to establish Genfinity as a leader of next generation social, educational, and innovative services and products.',
            twitter: 'https://twitter.com/valorkopeny'
        },
        {
            name: 'Colin Walsh',
            position: 'CTO & FOUNDER',
            image: 'COLIN',
            description: 'Colin Walsh is Co-Founder and CTO at Genfinity LLC.  He is responsible for overseeing the architecture and implementation of Genfinity’s technical goals.  With a background as a Principal Software Engineer across the Defense, Health Care, and Manufacturing spaces he has led multiple teams from idea to delivery.  His role within Genfinity is to guide the company to adoption at scale through solid architecture and strong AGILE principles to adapt to the ever changing Web3 environment.',
            twitter: 'https://twitter.com/ColinWalsh94'
        },
        {
            name: 'Sadaf Jadran',
            position: 'CMO & FOUNDER',
            image: 'SADAF',
            description: 'Sadaf Jadran is Co-Founder and CMO at Genfinity LLC.  She is responsible for developing and implementing digital marketing strategies for Genfinity’s product lines.  Sadaf is a seasoned Strategy Consultant specializing in tech and crypto startups. Innovative and forward focused, she’s helped multiple leading organizations design and implement effective product strategies successfully. With over a decade in delivering large scale strategic transformation programs across industries ranging from Retail to Financial services and a strong focus on emerging digital trends, her vision is to bring awareness and adoption to blockchain technology and the virtual economy. Her personal journey on the blockchain commenced in 2017 with her initial investments being Ethereum and XRP.',
            twitter: 'https://twitter.com/SadafJadran'
        },
        {
            name: 'Kaj Leroy',
            position: 'Advisor',
            image: 'KAJ',
            description: 'Kaj Leroy is an Advisor to Genfinity LLC.  He is responsible for advising Genfinity on platform development and marketing strategies, as well as connecting the dots in terms of partnerships and networking.  With a background and master’s degree in management, Kaj is an experienced entrepreneur who founded the XPUNKS and OnXRP franchise in 2021, with those companies becoming the leading ecosystem for NFTs, tokens, gaming, content and cross-chain functionality on the XRPL.',
            twitter: 'https://twitter.com/BradPunkhouse'
        },
        {
            name: 'Sarah Dillon',
            position: 'Community Development',
            image: 'SARAH',
            description: "Sarah Dillon is Community Manager at Genfinity LLC.  She is responsible for building and maintaining our community through content distribution and digital engagement.  Sarah is a social media expert and believes that Genfinity’s community is at the heart of everything that we do.  She has been a part of the blockchain community since 2017, navigating the 'highs and lows' of community sentiment.",
            twitter: 'https://twitter.com/GAL_SAL_'
        }
    ]

    return (
        <s.Container
            jc={"center"}
            ai={"center"}
            style={{
                width: "100%",
            }}
        >
            <div
                className='genfinity-team'
                style={{ 
                    width: '100%',
                    position: 'relative',
                }}
            >
                <div
                    className="content"
                    style={{ padding: '20px 0', height: 'fit-content' }}
                >
                    <span className={'founders-title'} >our team</span>
                    <hr 
                        style={{
                            borderTop: '1px solid #F43F5E',
                            margin: width > 700 ? '2rem 0 3rem' : '1rem 0'
                        }}
                    />
                    <s.Container fd="row" jc={"center"} ai={"center"} >
                    
                        {founders.map(member => {
                            return (
                                <div
                                    className="user"
                                    style={{
                                        margin: width > 700 ? '20px 40px' : '20px 20px',
                                        width: 'fit-content',
                                        textAlign: "center",
                                        fontFamily: 'Plus Jakarta Sans',
                                    }}
                                >
                                    <StyledProfileImg style={{ cursor: 'pointer' }} onClick={() => onContributorClick(member)} src={`/config/images/${member.image}.jpeg`} />
                                    <div
                                        style={{
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                            fontFamily: 'Plus Jakarta Sans',
                                            fontWeight: 600
                                        }}
                                    >
                                        {member.name}
                                    </div>
                                    <div
                                        style={{
                                            marginBottom: '10px',
                                            fontFamily: 'Plus Jakarta Sans'
                                        }}
                                    >
                                        {member.position}
                                    </div>
                                </div>
                            )
                        })}
                    </s.Container>
{/* 
                    <span className={'founders-title'} style={{ marginBottom: '2rem'}}  >our Team</span>
                    <hr 
                        style={{
                            borderTop: '2px solid #F43F5E',
                            margin: '1rem 0 3rem'
                        }}
                    />
                    <s.Container fd="row" jc={"center"} ai={"center"} style={{ marginBottom: '2rem'}} >
                    
                        {team.map(member => {
                            return (
                                <div
                                    className="user"
                                    style={{
                                        margin: '20px 60px',
                                        width: 'fit-content',
                                        textAlign: "center",
                                        fontFamily: 'raleway',
                                    }}
                                >
                                    <StyledProfileImg src={`/config/images/${member.image}.png`} />
                                    <div
                                        style={{
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                            fontFamily: 'Plus Jakarta Sans',
                                            fontWeight: 600
                                        }}
                                    >
                                        {member.name}
                                    </div>
                                    <div
                                        style={{
                                            marginBottom: '10px',
                                            fontFamily: 'Plus Jakarta Sans'
                                        }}
                                    >
                                        {member.position}
                                    </div>
                                </div>
                            )
                        })}
                    </s.Container> */}
                </div>
            </div>
        </s.Container>
    )
}

export default TeamList;
