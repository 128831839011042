import UserReducer from "./UserReducer";
import { combineReducers } from "redux";
import blockchainReducer from "./blockchainReducer";
import dataReducer from "./dataReducer";
import currentPriceReducer from "./currentPricesReducer";
import newsReducer from "./newsReducer";

const rootReducer = combineReducers({
    blockchain: blockchainReducer,
    data: dataReducer,
    news: newsReducer,
    prices: currentPriceReducer,
    user: UserReducer
});

export default rootReducer;