import firebase, { analytics, auth, firestore, storage } from "../firebase";

import moment from "moment";
import axios from "axios";

const posts = {};

posts.uploadImage = (image) => {
  return new Promise((resolve, reject) => {
    if (!image) {
      reject(new Error("No avatar"));

      return;
    }

    const imageFileTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/svg+xml",
    ];

    if (!imageFileTypes.includes(image.type)) {
      reject(new Error("Invalid file type"));

      return;
    }

    if (image.size > 20 * 1024 * 1024) {
      reject(new Error("Invalid size"));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No UID"));

      return;
    }

    const imageReference = storage
      .ref()
      .child("fawkes-api.appspot.com")
      .child("images")
      .child("posts")
      .child(uid)
      .child(image.name);

    imageReference
      .put(image)
      .then((uploadTaskSnapshot) => {
        imageReference
          .getDownloadURL()
          .then((photoURL) => {
            resolve(photoURL);
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });

};
posts.uploadPost = (post) => {
  return new Promise((resolve, reject) => {
    if (!post.imageUrl) {
      reject(new Error("No image URL"));

      return;
    }
    if (!post.title) {
      reject(new Error("No title"));

      return;
    }

    if (!post.description) {
      reject(new Error("No description"));

      return;
    }
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No UID"));

      return;
    }

    currentUser.getIdToken().then((idToken) => {
      axios({
        url: `https://genfinity.herokuapp.com/api/users/${uid}/posts`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
        data: post,
        withCredentials: true,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};


export default posts;
