import React from "react";
import * as s from "../../styles/globalStyles";
import styled from "styled-components";
import "typeface-raleway";
import TeamList from "../Dojo/TeamList";
import SocialLinks from "../Dojo/SocialLinks";
import { makeStyles } from "@material-ui/styles";
import '../../styles/about.scss';
import '../../styles/landingpage.scss';
import Footer from "./Footer";

export const StyledImg = styled.img`
    display: none;
    max-width: 200px;
    transition: width 0.5s;
    transition: height 0.5s;
    margin: auto;

    @media (min-width: 1040px) {
        display: block;
    }
    @media (min-width: 700px) {
        max-width: 250px;
        margin: 60px;
    };

    @media (min-width: 1400px) {
        max-width: 300px;
        margin-right: 200px;
    };

`;

export const Screenshots = styled.img`
    max-width: 100%;

  @media (min-width: 500px) {
    max-width: 500px;
  };
  @media (min-width: 900px) {
    max-width: 800px;
  };

  @media (min-width: 1400px) {
    max-width: 1200px;
  };
`;

export const Title = styled.div`
    font-family: Plus Jakarta Sans;
    margin: 0;
    text-align: center;
    font-size: 3rem;
    font-weight: 900;
    line-height: 1;

    @media (min-width: 500px) {
        font-size: 4rem;
    };
    @media (min-width: 550px) {
        font-size: 5rem;
    };

    @media (min-width: 900px) {
        font-size: 6rem;
    };

    @media (min-width: 1400px) {
        font-size: 7rem;
    };
`;

const useStyles = makeStyles((theme) => ({
    header: {
      color: theme.palette.text.primary,
    }
}));

const LandingPage = (props) => {
    const classes = useStyles();
    const { innerWidth: width, innerHeight: height } = window;

    const { onContributorClick } = props;

    return (
        <s.Screen>
            <s.Container
                className={'landing-page'}
            >
                <s.Container
                    fd={'column'}
                    flex={1}
                    ai={'center'}
                    style={{ 
                        textAlign: 'center', 
                        padding: '0 0 0 0'
                    }}
                >
                    <div>
                        <img className={'three-d-logo'} alt={"example"} src={"/config/images/gen3d_copy.png"} />
                        <div className={`header ${classes.header}`}>
                            GENFINITY  
                        </div>
                    </div>
                </s.Container>
            </s.Container>
            <div
                style={{
                    marginTop: width > 700 ? '1rem' : 0,
                    padding: width > 700 ? "20px 40px" : '10px 40px 0',
                    textAlign: 'center',
                    color: '#F43F5E',
                    fontSize: width > 700 ? '1.5rem' : '.8',
                    textTransform: 'uppercase',
                    letterSpacing: '.5rem'
                }}
            >
                litepaper <span style={{ fontWeight: 300 }}>coming soon</span>
            </div>
            <div className={'about-us'}>
                <div className="about-us-header" >
                    About us
                </div>
                <hr 
                    style={{
                        borderTop: '1px solid #F43F5E'
                    }}
                />
                <div className={'about-us-content'}>
                    Genfinity is a digital media organization providing tools and solutions for Web3 engagement and education. Our mission is to provide a trusted hub for communities to socialize, become informed, and successfully participate in the ever-evolving digital economy.
                </div>
                <TeamList onContributorClick={onContributorClick} />

                <SocialLinks />
            </div>
                
            <Footer />
        </s.Screen>
    )
}

export default LandingPage;