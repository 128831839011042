import React, { useState } from "react";

import AuthProviderList from "../Auth/AuthProviderList";
import authentication from "../../services/authentication";
import { updateUser, updateFirebaseUser } from "../../store/actions/UserAction";
import { useDispatch } from "react-redux";


import {
  Tooltip,
  IconButton,
  Grid,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import '../../styles/lighthouse.scss'
import '../../styles/signinForm.scss'
import validate from "validate.js";
import constraints from "../../data/constraints";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useNavigate } from "react-router";

const SigninForm = (props) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { changeAuthAction, openSnackbar } = props;

  const { innerWidth: width, innerHeight: height } = window;
  const [performingAction, setPerformingAction] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleEmailAddressChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const signIn = () => {

    const formErrors = validate(
      {
        emailAddress: emailAddress,
        password: password,
      },
      {
        emailAddress: constraints.emailAddress,
        password: constraints.password,
      }
    );

    if (formErrors) {
      setErrors(formErrors);
    } else {
      setErrors(null)
      setPerformingAction(null);
      authentication
        .signIn(emailAddress, password)
        .then((user) => {
          
          if (user) {
            dispatch(updateFirebaseUser(user));

            dispatch(updateUser(user));
            // user.getIdToken().then((idToken) => {
            //   dispatch(getUserById(idToken));
            // });
          }
          openSnackbar(
            `Signed in as ${user.displayName || user.email}`
          );
          navigate(`/user/${user.firebaseId}`);

        })
        .catch((reason) => {
          authentication.signOut();
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/invalid-email":
            case "auth/user-disabled":
            case "auth/user-not-found":
            case "auth/wrong-password":
              openSnackbar(message);
              return;

            default:
              openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          setPerformingAction(false);
          // window.location.href = "https://www.lighthouse.genfinity.io/";
        });
    }
  };
  const signInWithAuthProvider = (provider) => {
    setPerformingAction(true);
    authentication
      .signInWithAuthProvider(provider)
      .then((user) => {
        if (user && process.env.REACT_APP_TEST_USERS.split(", ").includes(user.email.toLowerCase())) {
          dispatch(updateUser(user));

            openSnackbar(
              `Signed in as ${user.displayName || user.email}`
            );

            navigate(`/user/${user.firebaseId}`);
          // login();
        } else {
          openSnackbar("Email address is not Whitelisted for alpha testing.");
          authentication.signOut();
        }
      })
      .catch((reason) => {
        authentication
          .signOut();
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          case "auth/account-exists-with-different-credential":
          case "auth/auth-domain-config-required":
          case "auth/cancelled-popup-request":
          case "auth/operation-not-allowed":
          case "auth/operation-not-supported-in-this-environment":
          case "auth/popup-blocked":
          case "auth/popup-closed-by-user":
          case "auth/unauthorized-domain":
            openSnackbar(message);
            return;

          default:
            openSnackbar(message);
            return;
        }
      })
      .finally(() => {
        setPerformingAction(false);
        // window.location.href = "https://www.lighthouse.genfinity.io/";
      });
  };

  const resetPassword = () => {

    const formErrors = validate(
      {
        emailAddress: emailAddress,
      },
      {
        emailAddress: constraints.emailAddress,
      }
    );

    if (formErrors) {
      setErrors(formErrors)
    } else {
      setErrors(null);
      setPerformingAction(true);
      authentication
        .resetPassword(emailAddress)
        .then((value) => {
          props.openSnackbar(
            `Sent password reset e-mail to ${emailAddress}`
          );
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/invalid-email":
            case "auth/missing-android-pkg-name":
            case "auth/missing-continue-uri":
            case "auth/missing-ios-bundle-id":
            case "auth/invalid-continue-uri":
            case "auth/unauthorized-continue-uri":
            case "auth/user-not-found":
              props.openSnackbar(message);
              return;

            default:
              props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          setPerformingAction(false);
        });
    }
  };

  return (
    <div className="lighthouse-login-form">
      <div className="lighthouse-login-content">
        <div
          style={{
            fontSize: '1rem',
            lineHeight: '1rem',
            marginBottom: '3rem',
            textTransform: 'none'
          }}
        >
          Sign in to the <span style={{ color: '#FF6565' }}>LightHouse Report</span>
        </div>
        <Grid container direction="column" spacing={2} style={{ width: '100%', margin: 'auto', display: 'flex', flexDirection: 'column'}}>
            <TextField
              color="primary"
              autoComplete="email"
              disabled={performingAction}
              error={!!(errors && errors.emailAddress)}
              helperText={
                errors && errors.emailAddress ? errors.emailAddress[0] : ""
              }
              label="E-mail"
              placeholder="john@doe.com"
              required
              type="email"
              value={emailAddress}
              variant="outlined"
              InputLabelProps={{ required: false }}
              onChange={handleEmailAddressChange}
              style={{ marginBottom: '1rem', flexGrow: 1 }}
            />

            <TextField
              autoComplete="current-password"
              disabled={performingAction}
              error={!!(errors && errors.password)}
              helperText={
                errors && errors.password ? errors.password[0] : ""
              }
              label="Password"
              placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
              required
              type={showPassword ? "text" : "password"} // <-- This is where the magic happens
              value={password}
              variant="outlined"
              InputLabelProps={{ required: false }}
              onChange={handlePasswordChange}
              style={{ width: '100%', marginBottom: '0rem' }}
              InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
        </Grid>
        <Tooltip title={!emailAddress ? "Enter email address above to reset" : `Send reset password email to: ${emailAddress}`} placement={'left'}>
          <span style={{ width: 'fit-content', margin: '.4rem 0 1.2rem auto' }}>
              <Button
              style={{ fontSize: '.7rem', width: 'fit-content' }}
              // color="primary"
              disabled={!emailAddress || performingAction}
              variant="text"
              onClick={resetPassword}
            >
              Forgot password
            </Button>
          </span>
        </Tooltip>
        <Button
          style={{ backgroundColor: '#0385F0', color: 'white', width: '100%', borderRadius: '1.5rem' }}
          disabled={!emailAddress || performingAction}
          variant="contained"
          onClick={() => signIn()}
        >
          Sign in
        </Button>

      </div>

      <AuthProviderList
        gutterBottom
        performingAction={performingAction}
        onAuthProviderClick={signInWithAuthProvider}
      />

      {/* <div style={{ display: 'flex', marginBottom: '2rem', alignItems: 'center' }}> */}
        <div style={{ textAlign: 'center', padding: '0 0 1rem 0', fontSize: '.8rem', lineHeight: '1rem', textTransform: 'none' }}>or</div>
      {/* </div> */}

      <div style={{ fontWeight: 700, textAlign: 'center', color: '#FF6565', cursor: 'pointer' }} onClick={() => changeAuthAction()} >Sign Up Here</div>

    </div>
  )
}

export default SigninForm;
