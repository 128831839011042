import '../../styles/footer.scss';
import { StyledLogoIcon } from '../Navigation/Header';

const Footer = () => {
  
  return (
    <div className="footer"
      style={{
        textAlign: 'center',
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <StyledLogoIcon
        alt={"example"} src={"/config/images/small_logo.png"} />
        <div className='rights' >ALL RIGHTS RESERVED BY GENFINITY LLC 2022</div>
    </div>
  )
}

export default Footer;