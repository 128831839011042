import React from "react";

import styled from "styled-components";

import * as s from "../../styles/globalStyles";


export const StyledLogoIcon = styled.img`
  max-height: 40px;
`;

const Header = () => {
    return (
        <s.Header
            flex={1}
        >
        <div 
            style={{
            padding: "10px 40px",
            }}
        >
            <StyledLogoIcon alt={"example"} src={"/config/images/small_logo.png"} />
        </div>
        </s.Header>
    )
}

export default Header;
