import React, { useState, useEffect, Suspense, lazy } from "react";

import { Link as RouterLink } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Link,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
} from "@material-ui/core";

import UserAvatar from "../Profile/UserAvatar";

import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { StyledLogoIcon } from "./Header";
// import SideDrawer from "./SideDrawer";

import '../../styles/navbar.scss';
import axios from "axios";
import { useNavigate } from "react-router";
import usePrevious from "../hooks/usePrevious";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom"
import { MenuRounded } from "@material-ui/icons";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Bar = (props) => {
  
  // Properties
  const {
    pairingData,
    connectionStatus,
    connectHashPack,
    performingAction,
    userData,
    roles,
    openMenu,
    sideDrawerAnchorEl,
    setSideDrawerAnchorEl,
    setTheme,
    theme
  } = props;

  const user = useSelector(state => state.user.user);

  const prevUser = usePrevious(user);
  const navigate = useNavigate();
  const location = useLocation()

  const [userList, setUserList] = useState([]);
  const [userListFilter, setUserListFilter] = useState("");
  const [open, setOpen] = React.useState(false);
  const [accountDropdownOpen, setAccountDropdownOpen] = React.useState(false);
  const [accountDropdownAnchorEl, setAccountDropdownAnchorEl] = useState(null);

  const anchorRef = React.useRef(null);
  const accountDropdownAnchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleAccountDropdownToggle = () => {
    setAccountDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleAccountDropdownClose = (event) => {
    if (accountDropdownAnchorRef.current && accountDropdownAnchorRef.current.contains(event.target)) {
      return;
    }

    setAccountDropdownOpen(false);
  };

  const clickOnLighthouse = () => {
    navigate('/lighthouse');
    setOpen(false);
  }

  const clickOnSocialHub = () => {
    navigate('/social');
    setOpen(false);
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  function handleAccountListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setAccountDropdownOpen(false);
    } else if (event.key === 'Escape') {
      setAccountDropdownOpen(false);
    }
  }


  const closeMenu = () => {
    setSideDrawerAnchorEl(null);
  };


  // useEffect(() => {
  //   if (user && !prevUser) {
  //     navigate(history.location.pathname.includes("user") ? `${user.firebaseId}` : `/user/${user.firebaseId}`)
  //   }
  // }, [user])

  // useEffect(() => {
  //   if (!!user) {
  //     axios({
  //       url: "https://genfinity.herokuapp.com/session/users",
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + user.firebaseId,
  //         },
  //         withCredentials: true,
  //     })
  //     .then((res) => {
  //       setUserList(res.data);
  //     }).catch(err => {
  //       setUserList([]);
  //       console.log(err);
  //     });
  //   }
    
  // }, [user])

  // Events
  const {
    onAboutClick,
    onSettingsClick,
    onSignOutClick,
    onSignUpClick,
    onSignInClick,
  } = props;

  const viewProfile = () => {
    navigate(location.pathname.includes("user") ? `${user.firebaseId}` : `/user/${user.firebaseId}`)
  }

  const SideDrawer = lazy(() => import("./SideDrawer"));

  return (
    <HideOnScroll>
    <AppBar style={{ backgroundColor: "black"}}>
      <Suspense fallback={<div />} >
        <SideDrawer
          pairingData={pairingData}
          connectionStatus={connectionStatus}
          connectHashPack={connectHashPack}
          onAboutClick={onAboutClick}
          onSettingsClick={onSettingsClick}
          onSignOutClick={onSignOutClick}
          anchorEl={sideDrawerAnchorEl}
          openMenu={openMenu}
          closeMenu={closeMenu}
          user={user}
          theme={theme}
          setTheme={setTheme}
        />
      </Suspense>
      <Toolbar>
        <Box display="flex" flexGrow={1}>
          <Link
            color="inherit"
            component={RouterLink}
            to="/"
            underline="none"
          >
            <StyledLogoIcon className={'desktop-logo'} alt={"example"} src={"/config/images/small_logo.png"} />
            <StyledLogoIcon className={'mobile-logo'}  alt={"example"} src={"/config/images/mobile_logo.png"} />

          </Link>
        </Box>

        <div className="flex nav-bar-buttons" style={{ fontSize: '1rem' }} >
          {!user
            ? (
              <>
              <Button color="secondary" variant="text" ref={anchorRef} style={{ fontFamily: 'Plus Jakarta Sans Extra Bold', fontWeight: 600 }}
                id="composition-button"
                className='header-button'
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                Products
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-center"
                transition
                disablePortal
                modifiers={[
                  {
                    name: 'arrow',
                    enabled: true,
                    options: {
                      element: anchorRef,
                    },
                  }
                ]}
              >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper style={{ borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem' }} >
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem className="dropdown-item" onClick={clickOnLighthouse}><span >Lighthouse Report</span></MenuItem>
                        <MenuItem className="dropdown-item" onClick={clickOnSocialHub}><span >Gen3 Social</span></MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            </>
            )
            : (
            <div className="flex" >
              <Button className='header-button' style={{ borderRadius: '.5rem', color: 'white', fontFamily: 'Plus Jakarta Sans Extra Bold', fontWeight: 600 }} variant="text" disabled={performingAction} onClick={() => {
                navigate('/explore');
                setOpen(false);
              }}>
                Explore
              </Button>
              <Button className='header-button' style={{ borderRadius: '.5rem', color: 'white', fontFamily: 'Plus Jakarta Sans Extra Bold', fontWeight: 600 }} variant="text" disabled={performingAction} onClick={() => {
                navigate('/nft-activity');
                setOpen(false);
              }}>
                NFT Activity
              </Button>
            </div>
            )
          }
        {!user
          ? (
            <div className="flex" >
              <Button className='header-button' style={{ color: 'white', fontFamily: 'Plus Jakarta Sans Extra Bold', fontWeight: 600 }} variant="text" disabled={performingAction} onClick={onSignInClick}>Log in</Button>
              <Button className='header-button' style={{ height: '28px', margin: 'auto', lineHeight: 'unset', color: 'white', fontFamily: 'Plus Jakarta Sans Extra Bold', fontWeight: 600, borderRadius: '1.5rem' }} variant="contained" color='secondary' disabled={performingAction} onClick={onSignUpClick}>Sign up</Button>
            </div>
          )
          : (
            <div >
              <div ref={accountDropdownAnchorRef} style={{ cursor: 'pointer', display: 'flex', flexWrap: 'nowrap', alignItems: 'center', paddingRight: '12px', color: 'white', backgroundColor: '#FF6565', borderRadius: '1.2rem', fontFamily: 'Plus Jakarta Sans Extra Bold', fontWeight: 800, fontSize: '1rem' }} variant="contained"
                id="composition-button"
                aria-controls={accountDropdownOpen ? 'composition-menu' : undefined}
                aria-expanded={accountDropdownOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={openMenu}
                >
                <IconButton
                  style={{ 
                    margin: 0,
                    padding: 0,
                    paddingLeft: '0',
                    marginRight: '0px'
                  }}
                  color="inherit"
                  disabled={performingAction}
                  // onClick={openMenu}
                >
                  <UserAvatar style={{ }} user={Object.assign(user, userData)} />
                </IconButton>
                <IconButton
                  style={{
                    margin: 0,
                    padding: 0,
                    paddingLeft: '0',
                    marginRight: '-1px'
                  }}
                  color="inherit"
                  disabled={performingAction}
                  // onClick={openMenu}
                >
                  <MenuRounded  />
                </IconButton>
              </div>
              <Popper
                open={accountDropdownOpen}
                anchorEl={accountDropdownAnchorRef.current}
                role={undefined}
                placement="bottom-center"
                transition
                disablePortal
              >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper style={{ borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem' }} >
                    <ClickAwayListener onClickAway={handleAccountDropdownClose}>
                      <MenuList
                        autoFocusItem={accountDropdownOpen}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleAccountListKeyDown}
                        style={{ padding: '6px'}}
                      >
                        {/* <MenuItem className="dropdown-item" onClick={clickOnLighthouseArchive}><span>LightHouse</span></MenuItem> */}
                        {/* <MenuItem className="dropdown-item" onClick={() => navigate('/rabbit-hole')}><span>Rabbit Hole</span></MenuItem> */}
                        {/* <MenuItem className="dropdown-item" onClick={viewProfile}><span>View Profile</span></MenuItem> */}
                        <MenuItem className="dropdown-item" onClick={() => onSettingsClick()}><span>Edit Profile</span></MenuItem>
                        <MenuItem className="dropdown-item" onClick={onSignOutClick}><span>Log Out</span></MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            </div>
          )
        }
        </div>
      </Toolbar>
    </AppBar>
    </HideOnScroll>
  );
}

export default Bar;
