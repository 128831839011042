import React, { Component, useState, useEffect } from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
} from "@material-ui/core";

import {
  Close as CloseIcon,
  AccountCircle as AccountCircleIcon,
  Palette as PaletteIcon,
  Link as LinkIcon,
  Security as SecurityIcon,
  CropOriginalRounded,
} from "@material-ui/icons";

import SwipeableViews from "react-swipeable-views";

import AccountTab from "../AccountTab";
import SecurityTab from "../SecurityTab";
import { auth } from "../../../firebase";
import axios from "axios";

import authentication from "../../../services/authentication";
import { useDispatch } from "react-redux";
import { updateUser, getUserById } from "../../../store/actions/UserAction";
import NftsTab from "../NftsTab/NftsTab";

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  tabs: {
    display: "initial",
  },
});

const tabs = [
  {
    key: "account",
    icon: <AccountCircleIcon />,
    label: "Account",
  },

  // {
  //   key: "security",
  //   icon: <SecurityIcon />,
  //   label: "Security",
  // },
  {
    key: "nfts",
    icon: <CropOriginalRounded />,
    label: "Hidden NFT's",
  }
];

const initialState = {
  selectedTab: 0,
};

const SettingsDialog = (props) => {

  const dispatch = useDispatch();
  const [selectedTab, setselectedTab] = useState(0)

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const currentUser = auth.currentUser;
    setFirebaseUser(currentUser)

    if (currentUser) {
      currentUser.getIdToken().then((idToken) => {
        dispatch(getUserById(currentUser.uid, idToken))
      });
    }
    
  }, [auth.currentUser]);

  const handleExited = () => {
    setselectedTab(0);
  };

  const handleTabChange = (event, value) => {
    setselectedTab(value);
  };

  const handleIndexChange = (index) => {
    setselectedTab(index);
  };

  // Styling
  const { classes } = props;

  // Dialog Properties
  const { dialogProps } = props;

  // Custom Properties
  const { userData, theme } = props;

  // Custom Functions
  const { openSnackbar } = props;

  // Custom Functions
  const { onDeleteAccountClick } = props;

  const saveUser = async (updatedUser) => {
    return authentication
      .updateUser(updatedUser)
      .then((savedUser) => {
        dispatch(updateUser(savedUser))
        setUser(savedUser);
        return savedUser;
      })
      .catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          default:
            openSnackbar(message);
            return;
        }
      });
  }

  return (
    <Dialog className="settings-dialog" fullWidth maxWidth="sm" style={{ minWidth: '200px' }} {...dialogProps} onExited={handleExited}>
      <DialogTitle disableTypography>
        <Typography variant="h6">Settings</Typography>

        <Tooltip title="Close">
          <IconButton
            className={classes.closeButton}
            onClick={dialogProps.onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <Tabs
        classes={{ root: classes.tabs }}
        style={{ overflow: "initial", minHeight: "initial" }}
        indicatorColor="primary"
        textColor="primary"
        value={selectedTab}
        variant="fullWidth"
        onChange={handleTabChange}
      >
        {tabs.map((tab) => {
          return <Tab key={tab.key} icon={tab.icon} label={tab.label} />;
        })}
      </Tabs>

      <SwipeableViews
        index={selectedTab}
        onChangeIndex={handleIndexChange}
      >
        <AccountTab
          saveUser={saveUser}
          user={user}
          userData={firebaseUser}
          openSnackbar={openSnackbar}
          onDeleteAccountClick={onDeleteAccountClick}
        />

        {/* <SecurityTab
          user={user}
          userData={userData}
          openSnackbar={openSnackbar}
        />
        */}
        <NftsTab />
      </SwipeableViews>
    </Dialog>
  );
}

SettingsDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Properties
  theme: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userData: PropTypes.object,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,

  // Custom Events
  onDeleteAccountClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(SettingsDialog);
