import React, { Component, useState } from "react";
import { useNavigate } from 'react-router-dom';

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  IconButton,
  Hidden,
  Grid,
  Button,
  Divider,
  TextField,
} from "@material-ui/core";

import { Close as CloseIcon, CheckRounded, ErrorOutlineRounded } from "@material-ui/icons";

import SubscriptionBox from "./SubscriptionBox";

import '../../styles/lighthouse.scss'
import '../../styles/confirmationDialog.scss'

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },

  divider: {
    margin: "auto",
  },

  grid: {
    marginBottom: theme.spacing(2),
  },

  dialog: {
    borderRadius: '20px !important'
  },
});

const ConfirmationDialog = (props) => {

  const { innerWidth: width, innerHeight: height } = window;

  console.log(props)
  const navigate = useNavigate();


  // Styling
  const { classes } = props;

  // Dialog Properties
  const { dialogProps } = props;

  const onClose = () => {
    dialogProps.onClose()

    navigate('/reports');
  }

  return (
    <Dialog
      className={classes.dialog}
      fullWidth
      maxWidth="md"
      {...dialogProps}
      onClose={() => onClose()}
    >
      <DialogTitle disableTypography>
        <Tooltip title="Close">
          <IconButton
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <div className="confirmation-wrapper" >
        <div className="confirmation">
          <div className='icon-wrapper'>
            <div className="icon">
              {dialogProps.success 
                ? <CheckRounded style={{ fontSize: width > 700 ? '3.4rem' : '3rem' }} color="secondary" />
                : <ErrorOutlineRounded style={{ fontSize: width > 700 ? '3.4rem' : '3rem' }} color="secondary" />
              }
            </div>
          </div>
          
          { dialogProps.success
            ? (<div style={{ margin: 'auto' }}>
                <div className='main-text'>
                  <span style={{ alignSelf: 'end' }}>Success! </span>
                </div>
                <div className="sub-text">
                  You are now subscribed to the LightHouse Report
                </div>
              </div>)
            : <div style={{ margin: 'auto' }}>
                <div className='main-text'>
                  <span style={{ alignSelf: 'end' }}>Transaction Canceled</span> 
                </div>
                <div className="sub-text">
                  Something went wrong. Please Try Again.
                </div>
              </div>
          }
        </div>
          {/* <button type="submit">
          CLICK HERE TO SUBSCRIBE
          </button> */}

        {/* <Button className="click-here-subscribe" variant="contained" color='secondary' onClick={() => subscribe()}>CLICK HERE TO SUBSCRIBE</Button> */}
      </div>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(ConfirmationDialog);
