
import React from "react";
import UserAvatar from "../UserAvatar";
import { List, ListItem } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const UserListOption = (props) => {

    const { onClose, user, visitUser, index } = props;
    console.log(user)

    return (
        <ListItem
            style={{ cursor: 'pointer' }}
            key={user.username}
            className={'user'}
            onClick={() => {
                visitUser(user.firebaseId)
                if (onClose) {
                    onClose();
                }
            }}
        >
            <UserAvatar context={'list'} user={user} />
            <div >
                <div className={'user-option-username'}>
                    @{user.username}
                </div>
                <div className={'user-option-name'}>
                    {user.firstName} {user.lastName}
                </div>
            </div>
        </ListItem>
    );
}

export default UserListOption;