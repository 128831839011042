import React, { Component, lazy, Suspense, useState } from "react";

import PropTypes from "prop-types";

import { BrowserRouter, Route, Navigate, Routes, useNavigate } from "react-router-dom";

// import UserPage from "../Profile/UserPage/MoralisUserPage";
// import PostDialog from "../PostDialog/PostDialog";

import { useSelector } from "react-redux";
import Dojo from "../Dojo/Dojo";
import LandingPage from "../LandingPage/LandingPage";

import '../../styles/router.scss'
import Footer from "../Navigation/Footer";
// import HomeFeed from "../HomeFeed/HomeFeed";
// import RabbitHole from "../RabbitHole/RabbitHole";
// import SearchPage from "../Search/SearchPage";
// import LighthouseHomepage from "../Lighthouse/LighthouseHomepage";

// import SocialHub from "../SocialHub/SocialHub";
import { CircularProgress } from "@material-ui/core";
// import HbarNftView from "../NFT/HbarNftView";
// import NftCollectionView from "../NFT/NftCollectionView";
// import HbarNftHomePage from "../NFT/HbarNftHomePage";

const Router = (props) => {
    // Properties

  const user = useSelector(state => state.user.user);

  const {
    theme,
    dialogHost,
    userData,
    roles,
    bar,
    onContributorClick,
    onSettingsClick,
    onUserListClick,
    onSignInClick,
    onConfirmation,
    openMenu
  } = props;

  const [postDiaglogOpen, setPostDiaglogOpen] = useState(false)
  // Functions
  const { openSnackbar } = props;
  // const navigate = useNavigate();

  const HbarNftHomePage = lazy(() => import("../NFT/HbarNftHomePage"));
  const HomeFeed = lazy(() => import("../HomeFeed/HomeFeed"));
  const SearchPage = lazy(() => import("../Search/SearchPage"));
  const UserPage = lazy(() => import("../Profile/UserPage/MoralisUserPage"));
  const HbarNftView = lazy(() => import("../NFT/HbarNftView"));
  const NftCollectionView = lazy(() => import("../NFT/NftCollectionView"));
  const RabbitHole = lazy(() => import("../RabbitHole/RabbitHole"));
  const PostDialog = lazy(() => import("../PostDialog/PostDialog"));
  const LighthouseHomepage = lazy(() => import("../Lighthouse/LighthouseHomepage"));
  const SocialHub = lazy(() => import("../SocialHub/SocialHub"));

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      {bar}
      {dialogHost}
      <div className={'main-content'} style={{ maxHeight: 'calc(100vh)', minHeight: '100vh'}}>
        <Suspense
          fallback={
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto' }}>
              <CircularProgress thickness={7} color="inherit" />
              <div style={{ marginTop: '1rem', fontSize: '2rem' }}>Loading Page</div>
            </div>
          }
        >
        <Routes >
          <Route path="/" exact element={<LandingPage onContributorClick={onContributorClick} />} />    

          <Route path="/lighthouse" element={<LighthouseHomepage onConfirmation={onConfirmation} onContributorClick={onContributorClick} openSnackbar={openSnackbar} onSignInClick={onSignInClick} />} />
          <Route path="/social" exact element={<SocialHub />} />

          {!!user &&
              <>
                <Route path="/explore" exact element={<SearchPage theme={theme} />} />
                <Route path="/nft-activity" exact element={<HbarNftHomePage theme={theme} />} />
                <Route path="/posts" exact element={<HomeFeed />} />
                <Route path="/user/:userId" element={<UserPage openMenu={openMenu} onUserListClick={onUserListClick} openSnackbar={openSnackbar} onSettingsClick={onSettingsClick} />} />
                
                <Route path="/nft/:tokenIdentifier" exact element={<HbarNftView theme={theme} />} />

                <Route path="/collection/:collectionId" exact element={<NftCollectionView theme={theme} />} />

                <Route path="/rabbit-hole" exact element={<RabbitHole theme={theme} />} />
                <Route path="/rabbit-hole/:selectedTab" element={<RabbitHole theme={theme} />} />
              </>
          }
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        </Suspense>
        <Suspense fallback={<div/>}>
          <PostDialog open={postDiaglogOpen} onClose={() => setPostDiaglogOpen(false)}  />
        </Suspense>
      </div>
      {/* <Footer postDiaglogOpen={postDiaglogOpen} onNewPostClick={onNewPostClick} onPostDialogClose={() => setPostDiaglogOpen(false)} /> */}

    </BrowserRouter>
  );
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default Router;
