import React, { Component } from "react";

import moment from "moment";

import {
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Hidden,
  TextField,
  Tooltip,
  IconButton,
  DialogTitle,
  Button,
  ListItemAvatar,
} from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import { auth } from "../../../firebase";
import axios from "axios";
import { getCurrentUser, getUserById } from "../../../store/actions/UserAction";

const NftsTab = () => {

  const user = useSelector(state => state.user.user);
  const dispatch = useDispatch();

  const unhideNFT = async (nftHash) => {

    const authUser = auth.currentUser;

    if (authUser) {
      authUser.getIdToken().then((idToken) => {
        axios({
          url: `https://genfinity.herokuapp.com/api/user/${authUser.uid}/nft/${nftHash}/unhide`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + idToken,
          },
          withCredentials: true,
        })
        .then((res) => {
          dispatch(getUserById(authUser.uid, idToken))
        }).catch(err => {
          console.log(err);
        });
      });
    }
  }
  return (
    <div style={{ marginTop: '1rem' }}>
      {/* <div style={{ fontSize: '1rem', margin: '1rem' }}>
        Hidden NFT's
      </div> */}
      <List disablePadding style={{ margin: '0 .5rem'}}>
        {!!user && 
        user.hiddenNfts.length == 0 ?
          <div style={{ fontSize: '1.4rem', textAlign: 'center', marginTop: '7rem' }}>
            You have no hidden NFTs
          </div>
        : user.hiddenNfts.map(nft => {
          const parsedNFT = JSON.parse(nft.nftMetadata)
          return (
            <ListItem style={{ padding: '0'}}>
              <ListItemIcon style={{ height: '3rem', width: '3rem', marginRight: '1rem'}} >
                <img src={parsedNFT.image} />
              </ListItemIcon>
              <ListItemText className="hidden-nft-text" primary={parsedNFT.creator} secondary={parsedNFT.name} />
              <ListItemSecondaryAction>
                <Button size='small' variant='outlined' onClick={() => unhideNFT(nft.nftHash)}>
                  Unhide
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
    </div>
  );
}

export default NftsTab;
