import React from "react";
import UserAvatar from "../UserAvatar";
import { List, ListItem } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import UserListOption from "./UserListOption";

const UserList = (props) => {

    const { onClose, users, visitUser } = props;
    console.log(users)

    const dummyUsers = [
        {
            username: 'test1',
            firstName: 'john',
            lastName: 'doe'
        },
        {
            username: 'test2',
            firstName: 'john',
            lastName: 'doe'
        },
        {
            username: 'test3',
            firstName: 'john',
            lastName: 'doe'
        },
        {
            username: 'test3',
            firstName: 'john',
            lastName: 'doe'
        },
        {
            username: 'test4',
            firstName: 'john',
            lastName: 'doe'
        },
        {
            username: 'test1',
            firstName: 'john',
            lastName: 'doe'
        },
        {
            username: 'test2',
            firstName: 'john',
            lastName: 'doe'
        },
        {
            username: 'test3',
            firstName: 'john',
            lastName: 'doe'
        },
        {
            username: 'test3',
            firstName: 'john',
            lastName: 'doe'
        },
        {
            username: 'test4',
            firstName: 'john',
            lastName: 'doe'
        }
    ]
    return (
        <List>
            {[...users].map((user, index) => <UserListOption user={user} visitUser={visitUser} onClose={onClose} /> )}
        </List>
    );
}

export default UserList;