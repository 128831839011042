import React, { Component, useState } from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  IconButton,
  Hidden,
  Grid,
  Button,
  Divider,
  TextField,
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

import SubscriptionBox from "./SubscriptionBox";

import '../../styles/lighthouse.scss'

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },

  divider: {
    margin: "auto",
  },

  grid: {
    marginBottom: theme.spacing(2),
  },

  dialog: {
    borderRadius: '20px !important'
  },
});

const SubscriptionDialog = (props) => {

  const { innerWidth: width, innerHeight: height } = window;

  console.log(props)


  // Styling
  const { classes } = props;

  // Dialog Properties
  const { dialogProps } = props;

  return (
    <Dialog
      className={classes.dialog}
      fullWidth
      maxWidth="md"
      {...dialogProps}
    >
      <DialogTitle disableTypography>
        <Tooltip title="Close">
          <IconButton
            className={classes.closeButton}
            onClick={dialogProps.onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <div  style={{ marginBottom: '2rem'}}>
        <SubscriptionBox />
      </div>
    </Dialog>
  );
}

SubscriptionDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(SubscriptionDialog);
