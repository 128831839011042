import React, { Component, useState, useEffect } from "react";

import PropTypes from "prop-types";

import validate from "validate.js";

import { withStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogTitle,

  Tooltip,
  IconButton,
  Hidden,
  Grid,
  Button,
  Divider,
  TextField,
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

import constraints from "../../../data/constraints";
import authentication from "../../../services/authentication";
import { useDispatch, useSelector } from "react-redux";
import { updateUser, updateFirebaseUser } from '../../../store/actions/UserAction';
import { useMoralis } from "react-moralis";
import SigninForm from "../../Lighthouse/SignIn";
import SignUpForm from "../../Lighthouse/Signup";
import { useNavigate } from 'react-router-dom';

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },

  divider: {
    margin: "auto",
  },

  grid: {
    marginBottom: theme.spacing(2),
  },
});

const SignInDialog = (props) => {

  // Styling
  const { classes } = props;

  // Dialog Properties
  const { dialogProps } = props;

  const { innerWidth: width, innerHeight: height } = window;

  const dispatch = useDispatch()
  const { authenticate, isAuthenticated, user } = useMoralis();

  const blockchain = useSelector((state) => state.blockchain);
  const walletConnector = useSelector((state) => state.blockchain.walletConnector)

  const [performingAction, setPerformingAction] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(null);
  
  console.log(dialogProps.action)
  const [authAction, setAuthAction] = useState(dialogProps.action === "signin" ? "signin" : "signup");
  console.log(authAction)

  useEffect(() => {
    setAuthAction(dialogProps.action)
  }, [dialogProps.action])
  const navigate = useNavigate();

  const login = async () => {
    if (!isAuthenticated) {
      if (width < 700 || !window.web3) {
        authenticate({
          provider: 'walletconnect',
          signingMessage:
          "Welcome to Genfinity!\n\n"+
          "Sign this message to associate your Web3 address with your Genfinity account."
        })
      } else {
        authenticate({
          signingMessage:
          "Welcome to Genfinity!\n\n"+
          "Sign this message to associate your Web3 address with your Genfinity account."
        })
      }
    }

    // TODO: OLD Web3 code 
    // const provider = await walletConnector.connect()
    // // dispatch(setWeb3(new Web3(provider)))
    // dispatch(connect(new Web3(provider), provider));
  }

  const getSignInButton = () => {

    if (emailAddress && !password) {
      return (
        <Button
          color="primary"
          disabled={!emailAddress || performingAction}
          variant="contained"
          onClick={() => sendSignInLinkToEmail()}
        >
          Send sign-in link
        </Button>
      );
    }

    return (
      <Button
        color="primary"
        disabled={!emailAddress || performingAction}
        variant="contained"
        onClick={() => signIn()}
      >
        Sign in
      </Button>
    );
  };

  const resetPassword = () => {

    const formErrors = validate(
      {
        emailAddress: emailAddress,
      },
      {
        emailAddress: constraints.emailAddress,
      }
    );

    if (formErrors) {
      setErrors(formErrors)
    } else {
      setErrors(null);
      setPerformingAction(true);
      authentication
        .resetPassword(emailAddress)
        .then((value) => {
          props.openSnackbar(
            `Sent password reset e-mail to ${emailAddress}`
          );
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/invalid-email":
            case "auth/missing-android-pkg-name":
            case "auth/missing-continue-uri":
            case "auth/missing-ios-bundle-id":
            case "auth/invalid-continue-uri":
            case "auth/unauthorized-continue-uri":
            case "auth/user-not-found":
              props.openSnackbar(message);
              return;

            default:
              props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          setPerformingAction(false);
        });
    }
  };

  const signIn = () => {

    const formErrors = validate(
      {
        emailAddress: emailAddress,
        password: password,
      },
      {
        emailAddress: constraints.emailAddress,
        password: constraints.password,
      }
    );

    if (formErrors) {
      setErrors(formErrors);
    } else {
      setErrors(null)
      setPerformingAction(null);
      authentication
        .signIn(emailAddress, password)
        .then((user) => {
          
          if (user) {
            dispatch(updateFirebaseUser(user));

            dispatch(updateUser(user));
            // user.getIdToken().then((idToken) => {
            //   dispatch(getUserById(idToken));
            // });
          }
          props.dialogProps.onClose(() => {
            const displayName = user.displayName;
            const emailAddress = user.email;

            props.openSnackbar(
              `Signed in as ${displayName || emailAddress}`
            );
            login();

          });
        })
        .catch((reason) => {
          authentication.signOut();
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/invalid-email":
            case "auth/user-disabled":
            case "auth/user-not-found":
            case "auth/wrong-password":
              props.openSnackbar(message);
              return;

            default:
              props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          setPerformingAction(false);
        });
    }
  };

  const sendSignInLinkToEmail = () => {

    const formErrors = validate(
      {
        emailAddress: emailAddress,
      },
      {
        emailAddress: constraints.emailAddress,
      }
    );

    if (formErrors) {
      setErrors(formErrors);
      return;
    }
    setErrors(null);
    setPerformingAction(true);
    authentication
      .sendSignInLinkToEmail(emailAddress)
      .then(() => {
        props.dialogProps.onClose(() => {
          props.openSnackbar(`Sent sign-in e-mail to ${emailAddress}`);
        });
      })
      .catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          case "auth/argument-error":
          case "auth/invalid-email":
          case "auth/missing-android-pkg-name":
          case "auth/missing-continue-uri":
          case "auth/missing-ios-bundle-id":
          case "auth/invalid-continue-uri":
          case "auth/unauthorized-continue-uri":
            props.openSnackbar(message);
            return;

          default:
            props.openSnackbar(message);
            return;
        }
      })
      .finally(() => {
        setPerformingAction(false);
      });
  };

  const signInWithAuthProvider = (provider) => {
    setPerformingAction(true);
    authentication
      .signInWithAuthProvider(provider)
      .then((user) => {
        if (user && process.env.REACT_APP_TEST_USERS.split(", ").includes(user.email.toLowerCase())) {
          dispatch(updateUser(user));
          props.dialogProps.onClose(() => {
            const displayName = user.displayName;
            const emailAddress = user.email;
  
            props.openSnackbar(
              `Signed in as ${displayName || emailAddress}`
            );
          });
          login();
        } else {
          props.openSnackbar("Email address is not Whitelisted for alpha testing.");
          authentication.signOut();
        }
      })
      .catch((reason) => {
        authentication
          .signOut();
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          case "auth/account-exists-with-different-credential":
          case "auth/auth-domain-config-required":
          case "auth/cancelled-popup-request":
          case "auth/operation-not-allowed":
          case "auth/operation-not-supported-in-this-environment":
          case "auth/popup-blocked":
          case "auth/popup-closed-by-user":
          case "auth/unauthorized-domain":
            props.openSnackbar(message);
            return;

          default:
            props.openSnackbar(message);
            return;
        }
      })
      .finally(() => {
        setPerformingAction(false);
      });
  };

  const handleKeyPress = (event) => {

    if (!emailAddress && !password) {
      return;
    }

    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === "Enter") {
      // if (emailAddress && !password) {
      //   sendSignInLinkToEmail();
      // } else {
      signIn();
      // }
    }
  };

  const handleExited = () => {
    setPerformingAction(false);
    setEmailAddress("");
    setPassword("");
    setErrors(null);
  };

  const handleEmailAddressChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick={performingAction}
      disableEscapeKeyDown={performingAction}
      {...dialogProps}
      onKeyPress={handleKeyPress}
      onExited={() => {
        handleExited();
        // setAuthAction('signin')
      }}
    >
      <DialogTitle disableTypography>
        <Tooltip title="Close">
          <IconButton
            className={classes.closeButton}
            disabled={performingAction}
            onClick={() => {
              dialogProps.onClose();
              setAuthAction('signin')
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      
      {authAction === "signup"
        ? <SignUpForm changeAuthAction={() => setAuthAction("signin")} openSnackbar={props.openSnackbar} />
        : <SigninForm changeAuthAction={() => setAuthAction("signup")} openSnackbar={props.openSnackbar} />
      }
    </Dialog>
  );
}

SignInDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignInDialog);
