import React, { useEffect, useState } from "react";

import styled from "styled-components";
import "typeface-raleway";

export const StyledButton = styled.div`
  padding: 10px;
  // border-radius: 50px;
  border: none;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: fit-content;
  cursor: pointer;
  // box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  // -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  // -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  // :active {
  //   box-shadow: none;
  //   -webkit-box-shadow: none;
  //   -moz-box-shadow: none;
  // }
`;

export const StyledBuyButton = styled.div`
  padding: 10px;
  border-radius: 50px;
  background-color: var(--secondary);
  border: none;
  padding: 10px;
  font-weight: bold;
  color: var(--primary);
  text-align: center;
  width: 250px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary-text);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;


export const StyledLogo = styled.img`
  width: 100%;
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledLogoIcon = styled.img`
  max-height: 40px;
`;

export const StyledMetamask = styled.img`
  height: 30px;
  width: 130px;
  vertical-align: text-bottom;
  margin-left: 10px;
  transition: width 0.5s;
  transition: height 0.5s;
`;


export const StyledSocialIcon = styled.img`
  max-width: 100px;
  max-height: 200px;
  @media (min-width: 767px) {
    max-width: 200px;
    max-height: 200px;
  }
  // width: 200px;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function Dojo() {

  return null;
}

export default Dojo;
