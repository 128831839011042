import React, { Component } from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import { Avatar, ListItemAvatar } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import {
  AccountCircle as AccountCircleIcon,
  Person as PersonIcon,
} from "@material-ui/icons";

import authentication from "../../../services/authentication";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: '4.5rem',
    height: '4.5rem',
    // width: '12vw',
    // height: '12vw',
    // maxHeight: '100px',
    // maxWidth: '100px',
    minHeight: '6.3rem',
    minWidth: '6.3rem'
  },
}));

const styles = (theme) => ({
  nameInitials: {
    cursor: "default",
  },
});

const UserAvatar = (props) => {
    // Styling
    const muiStyles = useStyles();

    const { classes, user } = props;

    // const user = useSelector(state => state.user.firebaseUser);
    // Properties
    const { context, defaultCursor, userPage } = props;

    if (context === "standalone") {
      if (!user) {
        return <AccountCircleIcon />;
      }

      const photoUrl = user.photoUrl;

      if (photoUrl) {
        return <Avatar style={{ cursor: 'default' }} className={userPage && muiStyles.large} alt="Avatar" src={photoUrl} />;
      }

      const nameInitials = authentication.getNameInitials({
        ...user,
      });

      if (nameInitials) {
        return (
          <Avatar className={userPage && muiStyles.large} alt="Avatar" src={"https://lh3.googleusercontent.com/a/default-user=s96-c"} />
        );
      }

      return <Avatar className={userPage && muiStyles.large} alt="Avatar" src={"https://lh3.googleusercontent.com/a/default-user=s96-c"} />;
    }

    if (context === "list") {
      if (!user) {
        return (
          <ListItemAvatar style={{ cursor: 'pointer' }}>
            <Avatar>
              <Avatar alt="Avatar" src={"https://lh3.googleusercontent.com/a/default-user=s96-c"} />
            </Avatar>
          </ListItemAvatar>
        );
      }

      const photoUrl = user.photoUrl;

      if (photoUrl) {
        return (
          <ListItemAvatar style={{ cursor: 'pointer' }} >
            <Avatar alt="Avatar" src={photoUrl} />
          </ListItemAvatar>
        );
      }

      const nameInitials = authentication.getNameInitials({
        ...user,
      });

      if (nameInitials) {
        return (
          <ListItemAvatar style={{ cursor: 'pointer' }}>
            <Avatar alt="Avatar" src={"https://lh3.googleusercontent.com/a/default-user=s96-c"} />
          </ListItemAvatar>
        );
      }

      return (
        <ListItemAvatar style={{ cursor: 'pointer' }}>
          <Avatar alt="Avatar" src={"https://lh3.googleusercontent.com/a/default-user=s96-c"} />
        </ListItemAvatar>
      );
    }

    return null;
}

UserAvatar.defaultProps = {
  context: "standalone",
};

UserAvatar.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Properties
  context: PropTypes.string,
  user: PropTypes.object.isRequired,
  defaultCursor: PropTypes.bool,
};

export default withStyles(styles)(UserAvatar);
