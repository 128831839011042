import React from "react";

import styled from "styled-components";

import * as s from "../../styles/globalStyles";
import '../../styles/about.scss';


export const StyledSocialIcon = styled.img`
  max-width: 100px;
  max-height: 100px;
  @media (min-width: 767px) {
    max-width: 150px;
    max-height: 150px;
  }
  // width: 200px;
`;

const SocialLinks = () => {

    return (
        <s.Container
            flex={1}
            ai={'center'}
            fd={"row"}
            jc={"center"}
            style={{ marginBottom: '2rem',  }}
        >
            <StyledSocialIcon
                alt={"example"}
                style={{ cursor: 'pointer'}}
                onClick={() => window.open("https://twitter.com/GenfinityIO", "_blank")}
                src={"/config/images/twitter-small.png"}
            />
            <StyledSocialIcon
                alt={"example"}
                style={{ cursor: 'pointer', marginLeft: '20px', height: '55px'}}
                onClick={() => window.open("https://discord.gg/cVBkq3Xhau", "_blank")}
                src={"/config/images/discord-black.png"}
            />
            <StyledSocialIcon
                alt={"example"}
                style={{ cursor: 'pointer', marginLeft: '30px'}}
                onClick={() => window.open("https://www.linkedin.com/company/genfinity-llc/", "_blank")}
                src={"/config/images/linkedin.png"}
            />
        </s.Container>
    )
}

export default SocialLinks
