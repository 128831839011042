import React, { Component, useState, useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Hidden,
  Box,
  TextField,
  Button,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import UserList from "./UserList";

import '../../../styles/userListDialog.scss';

const UserListDialog = (props) => {

    const { dialogProps } = props;

    const [ selectedTab, setSelectedTab ] = useState(dialogProps.defaultTab)

    useEffect(() => {
      setSelectedTab(dialogProps.defaultTab)
    }, [dialogProps.defaultTab])

    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };

    return (
      <Dialog
        {...dialogProps}
      >
        <div className={`user-list-dialog ${dialogProps.fullScreen ? "full-height" : null}`}>
          <DialogTitle>
            <div style={{ display: 'flex' }}>
              <Button
                style={{ margin: '0 0 0 auto'}}
                color="primary"
                onClick={dialogProps.onClose}
              >
                Close
              </Button>
              </div>

                <Tabs
                  value={selectedTab}
                  indicatorColor="secondary"
                  onChange={handleTabChange}
                  style={{ width: 'fit-content', margin: 'auto'}}
                >
                  <Tab label={`${dialogProps.followers.length} Followers`} />
                  <Tab label={`${dialogProps.following.length} Following`} />
                </Tabs>
          </DialogTitle>

          <div className={`dialog-body ${dialogProps.fullScreen ? "mobile-scroll" : "mobile-scroll"}`}>
            <DialogContent>
              <UserList visitUser={dialogProps.visitUser} onClose={dialogProps.onClose} users={selectedTab == 0 ? dialogProps.followers : dialogProps.following} />
            </DialogContent>
          </div>
        </div>
      </Dialog>
    );
}

export default UserListDialog;
