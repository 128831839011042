import React, { Component, useState, useEffect } from "react";

import PropTypes from "prop-types";

import validate from "validate.js";
import moment from "moment";

import { withStyles, makeStyles } from "@material-ui/core/styles";

import {
  DialogContent,
  Grid,
  Typography,
  Box,
  Fade,
  CircularProgress,
  Badge,
  Avatar,
  Fab,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Hidden,
  TextField,
  Tooltip,
  IconButton,
  Divider,
} from "@material-ui/core";

import {
  Close as CloseIcon,
  Photo as PhotoIcon,
  CloudUpload as CloudUploadIcon,
  Person as PersonIcon,
  Edit as EditIcon,
  PersonOutline as PersonOutlineIcon,
  Email as EmailIcon,
  Warning as WarningIcon,
  Check as CheckIcon,
  AccessTime as AccessTimeIcon,
  DeleteForever as DeleteForeverIcon,
  ExploreRounded,
  WorkRounded,
  ReceiptRounded,
  SaveOutlined,
  HighlightOffOutlined,
} from "@material-ui/icons";

import constraints from "../../../data/constraints";
import authentication from "../../../services/authentication";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../store/actions/UserAction";

const styles = (theme) => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
  },

  badge: {
    top: theme.spacing(2),
    right: -theme.spacing(2),
  },

  loadingBadge: {
    top: "50%",
    right: "50%",
  },

  avatar: {
    marginRight: "auto",
    marginLeft: "auto",

    width: '150px',
    height: '150px',
  },

  nameInitials: {
    cursor: "default",
  },

  personIcon: {
    fontSize: theme.spacing(7),
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    minHeight: "initial",
  },
});

const initialState = {
  profileCompletion: 0,
  securityRating: 0,
  showingField: "",
  avatar: null,
  avatarUrl: "",
  firstName: "",
  lastName: "",
  username: "",
  emailAddress: "",
  location: "",
  occupation: "",
  bio: "",
  performingAction: false,
  loadingAvatar: false,
  sentVerificationEmail: false,
  errors: null,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: '4.5rem',
    height: '4.5rem',
    width: '10vw',
    height: '10vw',
    maxHeight: '100px',
    maxWidth: '100px',
    minHeight: '4.3rem',
    minWidth: '4.3rem'
  },
}));

const AccountTab = (props) => {

  const muiStyles = useStyles();

  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);

  const [profileCompletion, setprofileCompletion] = useState(0)
  const [securityRating, setSecurityRating] = useState(0)
  const [showingField, setShowingField] = useState("")
  const [avatar, setAvatar] = useState(null)
  const [avatarUrl, setAvatarUrl] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [username, setUserName] = useState("")
  const [emailAddress, setEmailAddress] = useState("")
  const [location, setLocation] = useState("")
  const [occupation, setOccupation] = useState("")
  const [bio, setBio] = useState("")
  const [performingAction, setPerformingAction] = useState(false)
  const [loadingAvatar, setLoadingAvatar] = useState(false)
  const [sentVerificationEmail, setSentVerificationEmail] = useState(false)
  const [errors, setErrors] = useState(null)

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setBio(user.bio)
      setOccupation(user.occupation)
      setLocation(user.location)
      setUserName(user.username)
      // setPhotoUrl(user.photoUrl)
    }
  }, [user])

  useEffect(() => {
    setprofileCompletion(authentication.getProfileCompletion({
      ...user,
      ...userData,
    }))
    setSecurityRating(authentication.getSecurityRating(user, userData))
  }, []);

  const getNameInitialsOrIcon = () => {

    if (!user) {
      return null;
    }

    const { classes, userData } = props;

    if (!userData) {
      return <PersonIcon className={classes.personIcon} />;
    }

    const nameInitials = authentication.getNameInitials({
      ...user,
      ...userData,
    });

    if (nameInitials) {
      return (
        <Typography className={classes.nameInitials} variant="h2">
          {nameInitials}
        </Typography>
      );
    }

    return <PersonIcon className={classes.personIcon} />;
  };

  const uploadAvatar = () => {

    if (!avatar) {
      return;
    }

    setPerformingAction(true);
    setLoadingAvatar(true);
    
    authentication
      .changeAvatar(avatar)
      .then((photoUrl) => {
        // const { user, userData } = props;
        // setprofileCompletion(authentication.getProfileCompletion({
        //   ...user,
        //   ...userData,
        // }))
        // setAvatarUrl(photoUrl);
        saveUser(photoUrl);
      })
      .catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          default:
            props.openSnackbar(message);
            return;
        }
      })
      .finally(() => {
        setPerformingAction(false);
        setLoadingAvatar(false);
        setAvatar(null);
        setAvatarUrl("");
      });
  };

  const removeAvatar = () => {

    if (!!user && !user.photoURL && !avatar && !avatarUrl) {
      return;
    }

    if (!!user && ((!user.photoURL && avatar && avatarUrl) || (user.photoURL && avatar && avatarUrl))) {
      URL.revokeObjectURL(avatarUrl);
      setAvatar(null);
      setAvatarUrl("");
    } else if (!!user || (user.photoURL && !avatar && !avatarUrl)) {
      setPerformingAction(true);
      setLoadingAvatar(true);
      authentication
        .removeAvatar()
        .then((value) => {
          setprofileCompletion(authentication.getProfileCompletion({
            ...user,
            ...userData,
          }))
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            default:
              props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          setPerformingAction(false);
          setLoadingAvatar(false);
        });
    }
  };

  const showField = (fieldId) => {
    if (!fieldId) {
      return;
    }

    setShowingField(fieldId);
  };

  const hideFields = (updatedUser, callback) => {

    if (updatedUser) {
      setShowingField("");
      setFirstName(updatedUser.firstName)
      setLastName(updatedUser.lastName)
      setUserName(updatedUser.username)
      setEmailAddress(updatedUser.emailAddress)
      setBio(updatedUser.bio)
      setOccupation(updatedUser.occupation)
      setLocation(updatedUser.location)
      setErrors(null)

      if (callback && typeof callback === "function") {
        callback();
      }
    } else {
      setShowingField("");
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setUserName(user.username)
      setEmailAddress(user.emailAddress)
      setBio(user.bio)
      setOccupation(user.occupation)
      setLocation(user.location)
      setErrors(null)
      
      if (callback && typeof callback === "function") {
        callback();
      }
    }
  };

  const saveUser = (photoUrl) => {

    // const photoURL = avatarUrl.replace("blob:", "");
    props.saveUser({
      ...user,
      bio,
      occupation,
      firstName,
      lastName,
      location,
      username,
      photoUrl: photoUrl ? photoUrl : user.photoUrl
    }).then(updatedUser => {
      if (updatedUser) {
        dispatch(updateUser(updatedUser));
      }
      setPerformingAction(false)
      hideFields(updatedUser);
    });
  }

  const changeField = (fieldId) => {
    saveUser();
  };

  const handleKeyDown = (event, fieldId) => {
    if (!event || !fieldId) {
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    const key = event.key;

    if (!key) {
      return;
    }

    if (key === "Escape") {
      hideFields();
    } else if (key === "Enter") {
      changeField(fieldId);
    }
  };

  const handleAvatarChange = (event) => {
    if (!event) {
      return;
    }

    const files = event.target.files;

    if (!files) {
      return;
    }

    const avatar = files[0];

    if (!avatar) {
      return;
    }

    const fileTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/svg+xml",
    ];

    if (!fileTypes.includes(avatar.type)) {
      return;
    }

    if (avatar.size > 20 * 1024 * 1024) {
      return;
    }

    setAvatar(avatar);
    setAvatarUrl(URL.createObjectURL(avatar))
  };

  const handleFirstNameChange = (event) => {
    if (!event) {
      return;
    }

    setFirstName(event.target.value)
  };

  const handleLastNameChange = (event) => {
    if (!event) {
      return;
    }

    setLastName(event.target.value)
  };

  const handleUsernameChange = (event) => {
    if (!event) {
      return;
    }

    setUserName(event.target.value);
  };

  const handleEmailAddressChange = (event) => {
    if (!event) {
      return;
    }

    setEmailAddress(event.target.value)
  };

  const handleLocationChange = (event) => {
    if (!event) {
      return;
    }

    setLocation(event.target.value)
  };

  const handleOccupationChange = (event) => {
    if (!event) {
      return;
    }

    setOccupation(event.target.value)
  };

  const handleBioChange = (event) => {
    if (!event) {
      return;
    }

    setBio(event.target.value)
  };

  // Styling
  const { classes } = props;

  // Properties
  const { userData } = props;

  // Events
  const { onDeleteAccountClick } = props;

  const hasFirstName = user && user.firstName;
  const hasLastName = user && user.lastName;
  const hasUsername = user && user.username;
  const hasLocation = user && user.location;
  const hasOccupation = user && user.occupation;
  const hasBio = user && user.bio;

  return (
    <DialogContent classes={{ root: classes.dialogContent }}>
      <Box mb={2}>
          <Grid alignItems="center" container>
            <Grid item xs>
              <Box textAlign="center">
                <Box mb={1.5}>
                  {avatar && avatarUrl && (
                    <Badge
                      classes={{ badge: classes.badge }}
                      // badgeContent={
                      //   <Tooltip title="Remove">
                      //     <Fab
                      //       classes={{ sizeSmall: classes.small }}
                      //       color="secondary"
                      //       disabled={performingAction}
                      //       size="small"
                      //       onClick={removeAvatar}
                      //     >
                      //       <CloseIcon fontSize="small" />
                      //     </Fab>
                      //   </Tooltip>
                      // }
                    >
                      {loadingAvatar && (
                        <Badge
                          classes={{ badge: classes.loadingBadge }}
                          badgeContent={
                            <Fade
                              style={{ transitionDelay: "1s" }}
                              in={loadingAvatar}
                              unmountOnExit
                            >
                              <CircularProgress size={120} thickness={1.8} />
                            </Fade>
                          }
                        >
                          <Avatar
                            className={classes.avatar}
                            alt="Avatar"
                            src={avatarUrl}
                          />
                        </Badge>
                      )}

                      {!loadingAvatar && (
                        <Avatar
                          className={classes.avatar}
                          alt="Avatar"
                          src={avatarUrl}
                        />
                      )}
                    </Badge>
                  )}

                  {!avatar && !avatarUrl && (
                    <>
                      {userData.photoURL && (
                        <Badge
                          classes={{ badge: classes.badge }}
                          // badgeContent={
                          //   <Tooltip title="Remove">
                          //     <Fab
                          //       classes={{ sizeSmall: classes.small }}
                          //       color="secondary"
                          //       disabled={performingAction}
                          //       size="small"
                          //       onClick={removeAvatar}
                          //     >
                          //       <CloseIcon fontSize="small" />
                          //     </Fab>
                          //   </Tooltip>
                          // }
                        >
                          {loadingAvatar && (
                            <Badge
                              classes={{ badge: classes.loadingBadge }}
                              badgeContent={
                                <Fade
                                  style={{ transitionDelay: "1s" }}
                                  in={loadingAvatar}
                                  unmountOnExit
                                >
                                  <CircularProgress
                                    size={120}
                                    thickness={1.8}
                                  />
                                </Fade>
                              }
                            >
                              <Avatar
                                className={classes.avatar}
                                alt="Avatar"
                                src={user.photoUrl}
                              />
                            </Badge>
                          )}

                          {!loadingAvatar && (
                            <Avatar
                              className={classes.avatar}
                              alt="Avatar"
                              src={user.photoUrl}
                            />
                          )}
                        </Badge>
                      )}

                      {!userData.photoURL && (
                        <>
                          {loadingAvatar && (
                            <Badge
                              classes={{ badge: classes.loadingBadge }}
                              badgeContent={
                                <Fade
                                  style={{ transitionDelay: "1s" }}
                                  in={loadingAvatar}
                                  unmountOnExit
                                >
                                  <CircularProgress
                                    size={120}
                                    thickness={1.8}
                                  />
                                </Fade>
                              }
                            >
                             <Avatar className={classes.avatar} alt="Avatar" src={"https://lh3.googleusercontent.com/a/default-user=s96-c"} />
                            </Badge>
                          )}

                          {!loadingAvatar && (
                            <Avatar className={classes.avatar} alt="Avatar" src={"https://lh3.googleusercontent.com/a/default-user=s96-c"} />

                          )}
                        </>
                      )}
                    </>
                  )}
                </Box>

                {avatar && avatarUrl && (
                  <Button
                    color="secondary"
                    disabled={performingAction}
                    startIcon={<CloudUploadIcon />}
                    variant="contained"
                    onClick={uploadAvatar}
                  >
                    Upload
                  </Button>
                )}

                {!avatar && !avatarUrl && (
                  <>
                    <input
                      id="avatar-input"
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleAvatarChange}
                    />

                    <label htmlFor="avatar-input">
                      <Button
                        color="primary"
                        component="span"
                        disabled={performingAction}
                        startIcon={<PhotoIcon />}
                        variant="contained"
                      >
                        Choose...
                      </Button>
                    </label>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
      </Box>

      <List disablePadding>
        <ListItem>
          <Hidden xsDown>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
          </Hidden>

          {!hasFirstName && (
            <ListItemIcon>
              <Tooltip title="No first name">
                <WarningIcon color="error" />
              </Tooltip>
            </ListItemIcon>
          )}

          {showingField === "first-name" && (
            <>
            <TextField
              autoComplete="given-name"
              autoFocus
              disabled={performingAction}
              error={!!(errors && errors.firstName)}
              fullWidth
              label="First name"
              placeholder={hasFirstName && user.firstName}
              required
              type="text"
              value={firstName}
              variant="filled"
              InputLabelProps={{ required: false }}
              // onBlur={hideFields}
              onKeyDown={(event) => handleKeyDown(event, "first-name")}
              onChange={handleFirstNameChange}
            />
            <IconButton style={{ marginLeft: '.3rem'}} onClick={() => saveUser()}>
              <SaveOutlined />
            </IconButton>
            <IconButton style={{ marginLeft: '.3rem'}} onClick={hideFields}>
              <HighlightOffOutlined />
            </IconButton>
              {/* <Button
                style={{ marginLeft: '.3rem'}}
                color="secondary"
                variant="outlined"
                onClick={() => saveUser()}
              >
                Save
              </Button> */}
            </>
          )}

          {showingField !== "first-name" && (
            <>
              <ListItemText
                primary="First name"
                secondary={
                  hasFirstName
                    ? user.firstName
                    : "You don’t have a first name"
                }
              />

              <ListItemSecondaryAction>
                {hasFirstName && (
                  <Tooltip title="Change">
                    <div>
                      <IconButton
                        disabled={performingAction}
                        onClick={() => showField("first-name")}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}

                {!hasFirstName && (
                  <Button
                    color="primary"
                    disabled={performingAction}
                    variant="contained"
                    onClick={() => showField("first-name")}
                  >
                    Add
                  </Button>
                )}
              </ListItemSecondaryAction>
            </>
          )}
        </ListItem>

        <ListItem>
          <Hidden xsDown>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
          </Hidden>

          {!hasLastName && (
            <ListItemIcon>
              <Tooltip title="No last name">
                <WarningIcon color="error" />
              </Tooltip>
            </ListItemIcon>
          )}

          {showingField === "last-name" && (
            <>
            <TextField
              autoComplete="family-name"
              autoFocus
              disabled={performingAction}
              error={!!(errors && errors.lastName)}
              fullWidth
              label="Last name"
              placeholder={hasLastName && user.lastName}
              required
              type="text"
              value={lastName}
              variant="filled"
              InputLabelProps={{ required: false }}
              // onBlur={hideFields}
              onKeyDown={(event) => handleKeyDown(event, "last-name")}
              onChange={handleLastNameChange}
            />
             <IconButton style={{ marginLeft: '.3rem'}} onClick={() => saveUser()}>
              <SaveOutlined />
            </IconButton>
            <IconButton style={{ marginLeft: '.3rem'}} onClick={hideFields}>
              <HighlightOffOutlined />
            </IconButton>
            </>
          )}

          {showingField !== "last-name" && (
            <>
              <ListItemText
                primary="Last name"
                secondary={
                  hasLastName
                    ? user.lastName
                    : "You don’t have a last name"
                }
              />

              <ListItemSecondaryAction>
                {hasLastName && (
                  <Tooltip title="Change">
                    <div>
                      <IconButton
                        disabled={performingAction}
                        onClick={() => showField("last-name")}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}

                {!hasLastName && (
                  <Button
                    color="primary"
                    disabled={performingAction}
                    variant="contained"
                    onClick={() => showField("last-name")}
                  >
                    Add
                  </Button>
                )}
              </ListItemSecondaryAction>
            </>
          )}
        </ListItem>

        <ListItem>
          <Hidden xsDown>
            <ListItemIcon>
              <PersonOutlineIcon />
            </ListItemIcon>
          </Hidden>

          {!hasUsername && (
            <ListItemIcon>
              <Tooltip title="No username">
                <WarningIcon color="error" />
              </Tooltip>
            </ListItemIcon>
          )}

          {showingField === "username" && (
            <>
            <TextField
              autoComplete="username"
              autoFocus
              disabled={performingAction}
              error={!!(errors && errors.username)}
              fullWidth
              label="Username"
              placeholder={hasUsername && user.username}
              required
              type="text"
              value={username}
              variant="filled"
              InputLabelProps={{ required: false }}
              // onBlur={hideFields}
              onKeyDown={(event) => handleKeyDown(event, "username")}
              onChange={handleUsernameChange}
            />
             <IconButton style={{ marginLeft: '.3rem'}} onClick={() => saveUser()}>
              <SaveOutlined />
            </IconButton>
            <IconButton style={{ marginLeft: '.3rem'}} onClick={hideFields}>
              <HighlightOffOutlined />
            </IconButton>
            </>
          )}

          {showingField !== "username" && (
            <>
              <ListItemText
                primary="Username"
                secondary={
                  hasUsername
                    ? user.username
                    : "You don’t have a username"
                }
              />

              <ListItemSecondaryAction>
                {hasUsername && (
                  <Tooltip title="Change">
                    <div>
                      <IconButton
                        disabled={performingAction}
                        onClick={() => showField("username")}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}

                {!hasUsername && (
                  <Button
                    color="primary"
                    disabled={performingAction}
                    variant="contained"
                    onClick={() => showField("username")}
                  >
                    Add
                  </Button>
                )}
              </ListItemSecondaryAction>
            </>
          )}
        </ListItem>

        <ListItem>
          <Hidden xsDown>
            <ListItemIcon>
              <ExploreRounded />
            </ListItemIcon>
          </Hidden>

          {!hasLocation && (
            <ListItemIcon>
              <Tooltip title="No Location">
                <WarningIcon color="error" />
              </Tooltip>
            </ListItemIcon>
          )}

          {showingField === "location" && (
            <>
            <TextField
              autoComplete="current location"
              autoFocus
              disabled={performingAction}
              error={!!(errors && errors.location)}
              fullWidth
              label="Location"
              placeholder={hasLocation && user.location}
              required
              type="text"
              value={location}
              variant="filled"
              InputLabelProps={{ required: false }}
              // onBlur={hideFields}
              onKeyDown={(event) => handleKeyDown(event, "location")}
              onChange={handleLocationChange}
            />
             <IconButton style={{ marginLeft: '.3rem'}} onClick={() => saveUser()}>
              <SaveOutlined />
            </IconButton>
            <IconButton style={{ marginLeft: '.3rem'}} onClick={hideFields}>
              <HighlightOffOutlined />
            </IconButton>
            </>
          )}

          {showingField !== "location" && (
            <>
              <ListItemText
                primary="Location"
                secondary={
                  hasLocation
                    ? user.location
                    : "You don’t have a location"
                }
              />

              <ListItemSecondaryAction>
                {hasLocation && (
                  <Tooltip title="Change">
                    <div>
                      <IconButton
                        disabled={performingAction}
                        onClick={() => showField("location")}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}

                {!hasLocation && (
                  <Button
                    color="primary"
                    disabled={performingAction}
                    variant="contained"
                    onClick={() => showField("location")}
                  >
                    Add
                  </Button>
                )}
              </ListItemSecondaryAction>
            </>
          )}
        </ListItem>

        <ListItem>
          <Hidden xsDown>
            <ListItemIcon>
              <WorkRounded />
            </ListItemIcon>
          </Hidden>

          {!hasOccupation && (
            <ListItemIcon>
              <Tooltip title="No Occupation">
                <WarningIcon color="error" />
              </Tooltip>
            </ListItemIcon>
          )}

          {showingField === "occupation" && (
            <>
            <TextField
              autoComplete="current occupation"
              autoFocus
              disabled={performingAction}
              error={!!(errors && errors.occupation)}
              fullWidth
              label="Occupation"
              placeholder={hasOccupation && user.occupation}
              required
              type="text"
              value={occupation}
              variant="filled"
              InputLabelProps={{ required: false }}
              // onBlur={hideFields}
              onKeyDown={(event) => handleKeyDown(event, "occupation")}
              onChange={handleOccupationChange}
            />
             <IconButton style={{ marginLeft: '.3rem'}} onClick={() => saveUser()}>
              <SaveOutlined />
            </IconButton>
            <IconButton style={{ marginLeft: '.3rem'}} onClick={hideFields}>
              <HighlightOffOutlined />
            </IconButton>
            </>
          )}

          {showingField !== "occupation" && (
            <>
              <ListItemText
                primary="Occupation"
                secondary={
                  hasOccupation
                    ? user.occupation
                    : "You don’t have a occupation"
                }
              />

              <ListItemSecondaryAction>
                {hasOccupation && (
                  <Tooltip title="Change">
                    <div>
                      <IconButton
                        disabled={performingAction}
                        onClick={() => showField("occupation")}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}

                {!hasOccupation && (
                  <Button
                    color="primary"
                    disabled={performingAction}
                    variant="contained"
                    onClick={() => showField("occupation")}
                  >
                    Add
                  </Button>
                )}
              </ListItemSecondaryAction>
            </>
          )}
        </ListItem>

        <ListItem>
          <Hidden xsDown>
            <ListItemIcon>
              <ReceiptRounded />
            </ListItemIcon>
          </Hidden>

          {!hasBio && (
            <ListItemIcon>
              <Tooltip title="No Bio">
                <WarningIcon color="error" />
              </Tooltip>
            </ListItemIcon>
          )}

          {showingField === "bio" && (
            <>
            <TextField
              autoComplete="current bio"
              autoFocus
              disabled={performingAction}
              error={!!(errors && errors.bio)}
              fullWidth
              label="Bio"
              placeholder={hasBio && user.bio}
              required
              type="text"
              value={bio}
              variant="filled"
              multiline
              rows={4}
              InputLabelProps={{ required: false }}
              // onBlur={hideFields}
              // onKeyDown={(event) => handleKeyDown(event, "bio")}
              onChange={handleBioChange}
            />
             <IconButton style={{ marginLeft: '.3rem'}} onClick={() => saveUser()}>
              <SaveOutlined />
            </IconButton>
            <IconButton style={{ marginLeft: '.3rem'}} onClick={hideFields}>
              <HighlightOffOutlined />
            </IconButton>
            </>
          )}
          {showingField !== "bio" && (
            <>
              <ListItemText
                primary="Bio"
                secondary={
                  hasBio
                    ? user.bio
                    : "You don’t have a bio"
                }
              />

              <ListItemSecondaryAction>
                {hasBio && (
                  <Tooltip title="Change">
                    <div>
                      <IconButton
                        disabled={performingAction}
                        onClick={() => showField("bio")}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}

                {!hasBio && (
                  <Button
                    color="primary"
                    disabled={performingAction}
                    variant="contained"
                    onClick={() => showField("bio")}
                  >
                    Add
                  </Button>
                )}
              </ListItemSecondaryAction>
            </>
          )}
        </ListItem>

        <ListItem>
          <Hidden xsDown>
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
          </Hidden>

          <Hidden xsDown>
            <ListItemText
              primary="Signed in"
              secondary={moment(userData.metadata.lastSignInTime).format("LLLL")}
            />
          </Hidden>

          <Hidden smUp>
            <ListItemText
              primary="Signed in"
              secondary={moment(userData.metadata.lastSignInTime).format("llll")}
            />
          </Hidden>
        </ListItem>

        <Box mt={1} mb={1}>
          <Divider light />
        </Box>

        {/* <ListItem>
          <Hidden xsDown>
            <ListItemIcon>
              <DeleteForeverIcon />
            </ListItemIcon>
          </Hidden>

          <ListItemText
            primary="Delete account"
            secondary="Accounts can’t be recovered"
          />

          <ListItemSecondaryAction>
            <Button
              color="secondary"
              disabled={performingAction}
              variant="contained"
              onClick={onDeleteAccountClick}
            >
              Delete
            </Button>
          </ListItemSecondaryAction>
        </ListItem> */}
      </List>
    </DialogContent>
  );
}

AccountTab.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Properties
  user: PropTypes.object.isRequired,
  userData: PropTypes.object,

  // Functions
  openSnackbar: PropTypes.func.isRequired,

  // Events
  onDeleteAccountClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(AccountTab);
