import React, { useState, useEffect } from "react";

import {
  Button
} from "@material-ui/core";

import '../../styles/lighthouse.scss'
import '../../styles/signinForm.scss'

import { auth } from "../../firebase";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { CheckRounded } from "@material-ui/icons";
import { getCurrentUser } from "../../store/actions/UserAction";

const SubscriptionBox = (props) => {

  const { onSignInClick } = props
  const { innerWidth: width, innerHeight: height } = window;

  const usersEmail = auth.currentUser && auth.currentUser.email;
  const dispatch = useDispatch()

  const user = useSelector(state => state.user.user);

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   if (user !== null && user.stripeSubscriptionId !== null && auth.currentUser !== null) {
  //     // if (user)
  //     auth.currentUser.getIdToken().then((idToken) => {
  //       setIsLoading(true);
  //       axios({
  //         url: `https://genfinity.herokuapp.com/api/payments/subscription-test/${user.stripeSubscriptionId}`,
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + idToken,
  //         },
  //         withCredentials: true,
  //       }).then(res => {
  //         if (res.data !== null) {
  //           dispatch(getCurrentUser(user.firebaseId))
  //           setIsSubscribed(res.data.price.active)
  //           setIsLoading(false);
  //         }
  //         console.log(res)
  //       }).catch(err => {
  //         console.log(err);
  //         setIsLoading(false);
  //       });
  //     })
  //   } else {
  //     setIsLoading(false)
  //   }
  // }, [user])

  // const checkSubscriptionStatus = () => {
  //   if (user !== null && user.stripeSubscriptionId !== null && auth.currentUser !== null) {
  //     // if (user)
  //     auth.currentUser.getIdToken().then((idToken) => {
  //       axios({
  //         url: `https://genfinity.herokuapp.com/api/payments/subscription-test/${user.stripeSubscriptionId}`,
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + idToken,
  //         },
  //         withCredentials: true,
  //       }).then(res => {
  //         if (res.data !== null) {
  //           setIsSubscribed(res.data.price.active)
  //         }
  //         console.log(res)
  //       }).catch(err => {
  //         console.log(err);
  //       });
  //     })
  //   }
  // }

  return (
    <div>

        {/* <div className='normal-price'>
          <div className="strike-through">
            X
          </div>
          <div className="price">
            $30
          </div>
          <div className='per-month'>
            per month
          </div>
        </div> */}
      <div className="subscription-model-wrapper" >
        {/* <div className="coming-soon">
          <div className="coming">COMING</div>
          <div className="coming-soon-button" disabled>SOON!</div>
        </div> */}
        <div className="monthly-subscription">
          <div className='monthly-price'>
            <div className="price">
              FREE!
            </div>
            {/* <div className='per-month'>
              per month
            </div> */}
          </div>
          <div className='monthly-subscription-text' style={{  }}>
            <div className="main-text" style={{ width: 'fit-content' }}>Subscription</div>
            <div className="sub-text">Lifetime access to the Lighthouse Report</div>
          </div>
        </div>
        <div className="subscription-content" >
          <div className='free-subscription' >
            Your Free Subscription:
          </div>
          <div className='subscription-details'>
            The LightHouse Report contains exclusive content from industry leading
            crypto contributors. On a monthly basis the following sections are presented
            by our contributors:

            <ul className='report-sections'>
              <li>Adoption Review</li>
              <li>Market Sentiment</li>
              <li>Technical Analysis</li>
              <li>Defi & Web3</li>
              <li>Tradfi</li>
              <li>Market Asia</li>
              <li>Legal Review</li>
              <li>Executive Suite (interviews with CEO’s)</li>
              <li>The Grid</li>
              <li>Utility Deep Dive</li>
              <li>Macroeconomics</li>
            </ul>
            <span style={{ fontSize: '.8rem' }}>*** Subject To Change ***</span>
          </div>
          {user == null
          ? <Button style={{ lineHeight: '1.2rem !important', width: '100%' }} className="register-here" color="secondary" variant="contained" onClick={() => onSignInClick()} >Register For Free Access Here </Button>
          : <div className='click-here'>
              <span style={{ alignSelf: 'end' }}>Registered</span> <CheckRounded style={{ fontSize: width > 700 ? '2.4rem' : '2rem', marginRight: width > 700 ? '-16px' : '-10px' }} color="secondary" />
            </div>
        }
        </div>
        
        {/* <form action={`https://genfinity.herokuapp.com/api/payments/create-checkout-session-test/${usersEmail}`} method="POST">
          { isLoading
            ? <div className='click-here'>
                <span style={{ alignSelf: 'end' }}>Loading... </span>
              </div>
            : isSubscribed
            ? <div className='click-here'>
                <span style={{ alignSelf: 'end' }}>Subscribed</span> <CheckRounded style={{ fontSize: width > 700 ? '2.4rem' : '2rem', marginRight: width > 700 ? '-16px' : '-10px' }} color="secondary" />
              </div>
            : <Button type="submit" className="click-here-subscribe" variant="contained" color='secondary' disabled={auth.currentUser == null} >{auth.currentUser == null ? <span>LOG IN TO SUBSCRIBE</span> : <span>CLICK HERE TO SUBSCRIBE</span>}</Button>
          }
        </form> */}
        {/* <Button className="click-here-subscribe" variant="contained" color='secondary' onClick={() => subscribe()}>CLICK HERE TO SUBSCRIBE</Button> */}
      </div>
    </div>
  )
}

export default SubscriptionBox;
